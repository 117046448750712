import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import Lottie from 'react-lottie';
import * as animationData from 'assets/lottie/animation_onboarding_premium_first.json';
import BlockButton from 'components/buttons/BlockButton';
import PageTitle from 'components/PageTitle';

export default function TrainingPlanStarted({ onClose }) {
  const { t } = useTranslation('common');

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  return (
    <div>
      <div className="animated fadeIn">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={defaultOptions.isStopped}
          isPaused={defaultOptions.isPaused}
        />
      </div>

      <PageTitle
        title={t('coaching-page.plan-started')}
        text={t('coaching-page.plan-started-text')}
      />

      <BlockButton
        isBottom
        text={t('continue')}
        onClick={() => {
          onClose();
        }}
      ></BlockButton>
    </div>
  );
}
