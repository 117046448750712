import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledInputRenderer = styled.div`
  width: 100%;

  .input-container {
    width: 100%;
    margin-top: 10px;

    textarea {
      border: 1px solid #ddd;
      border-radius: 10px;
      font-size: 16px;
      padding: 10px;
      height: 100px;
      line-height: 1.2;
      background-color: #fff;

      &::placeholder {
        color: #ddd;
      }

      @media only screen and (max-width: 375px) {
        font-size: 14px;
      }
    }
  }
`;

// Main component to render inputs based on type
const InputRenderer = ({ session }) => {
  const { t } = useTranslation('common');

  const { inputs } = session;

  if (!inputs || inputs.length === 0) {
    return null;
  }

  return (
    <StyledInputRenderer>
      {inputs.map((input) => {
        switch (input.type) {
          case 'text':
            return (
              <div key={input.id} className="input-container">
                <textarea
                  type="text"
                  placeholder={input.description || t('coach-input-placeholder')}
                  required={input.required}
                />
                {/*input.allow_images && <div>Images are allowed</div>*/}
              </div>
            );
          // Add more cases for other input types as needed
          default:
            return null;
        }
      })}
    </StyledInputRenderer>
  );
};

InputRenderer.propTypes = {
  session: PropTypes.shape({
    inputs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        required: PropTypes.bool,
        key: PropTypes.string,
        headline: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string.isRequired,
        allow_images: PropTypes.bool,
      }),
    ),
  }).isRequired,
};

export default InputRenderer;
