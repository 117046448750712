import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import styled, { keyframes } from 'styled-components'
import CloseIcon from './SVG/CloseIcon'
import { StyledElements } from 'StyledElements'

// Keyframe animation for modal entry
const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`

// Styled components for the modal and background
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.01);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  overflow: hidden;
`

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  animation: ${({ shouldAnimateOut }) => (shouldAnimateOut ? slideOut : slideIn)} 0.5s ease;
  background: linear-gradient(130deg, #fff, #f8efea);
  color: #111;

  .react-modal-content {
    margin: 0 auto;
    max-width: 580px;
    width: 100%;
    display: flex;
    //align-items: center;
    justify-content: center;
    min-height: 90vh;
    padding-top: 0;
  }

  .modal-close {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9999;
    box-shadow: #cccccc 0 0 16px;
    transition: all 0.3s;

    svg {
      height: 20px;
      width: 20px;
      fill: #111;
    }

    &:hover {
      transform: rotate(90deg);
    }

    @media (min-width: 720px) {
      width: 60px;
      height: 60px;
      top: 5%;
      right: 5%;

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }

  .modal-back {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9999;
    box-shadow: #cccccc 0 0 16px;
    transition: all 0.3s;

    svg {
      height: 20px;
      width: 20px;
      fill: #111;
    }

    &:hover {
      transform: rotate(90deg);
    }

    @media (min-width: 720px) {
      width: 60px;
      height: 60px;
      top: 5%;
      right: 5%;

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }
`

// Modal component
const Modal = ({ onClose, children }) => {
  const [shouldAnimateOut, setShouldAnimateOut] = useState(false)
  const [portalRoot, setPortalRoot] = useState(null)

  useEffect(() => {
    const root = document.createElement('div')
    document.body.appendChild(root)
    setPortalRoot(root)
    // Prevent scrolling on the background content when the modal is open
    document.body.style.overflow = 'hidden'
    document.documentElement.style.overflow = 'hidden' // Add this line

    const handlePopState = () => {
      handleClose()
    }

    const modalPushState = () => {
      window.history.pushState(null, null, null) // Adds an empty state to the history
    }

    window.addEventListener('popstate', handlePopState)

    modalPushState() // Add this line to push state when the modal is opened

    return () => {
      document.body.removeChild(root)
      // Re-enable scrolling on the background content when the modal is closed
      document.body.style.overflow = 'auto'
      document.documentElement.style.overflow = 'auto' // Add this line
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  const handleClose = () => {
    setShouldAnimateOut(true)
    setTimeout(() => {
      onClose()
    }, 500)
  }

  if (!portalRoot) return null

  return createPortal(
    <StyledElements>
      <ModalBackground onClick={handleClose} data-id="screenshot-wrapper">
        <ModalContainer shouldAnimateOut={shouldAnimateOut} onClick={(e) => e.stopPropagation()}>
          <div
            className="modal-close hide-to-share"
            onClick={() => {
              setShouldAnimateOut(true)
              setTimeout(() => {
                onClose()
              }, 500)
            }}
          >
            <CloseIcon />
          </div>
          <div className="react-modal-content" data-id="screenshot-area">
            {children}
          </div>
        </ModalContainer>
      </ModalBackground>
    </StyledElements>,
    portalRoot,
  )
}

export default Modal
