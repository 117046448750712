import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import Lottie from 'react-lottie';
import * as animationData from 'assets/lottie/animation_onboarding_benefits.json';
import BlockButton from 'components/buttons/BlockButton';

export default function HadBirthChartResultStep({ currentStep, setCurrentStep, scrollToWidget }) {
  const { t } = useTranslation('common');

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  return (
    <div>
      <div className="animated fadeIn">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={defaultOptions.isStopped}
          isPaused={defaultOptions.isPaused}
        />
      </div>

      <div className="story-text">
        <div className="headline animation animated fadeIn">
          {t('welcome-page.birth-chart-explain-topline')}
        </div>

        <b className="animation animated fadeIn">
          {t('welcome-page.birth-chart-explain-headline')}
        </b>

        <div className="animation animated fadeIn">
          {t('welcome-page.birth-chart-explain-text')}
        </div>
      </div>

      <BlockButton
        isBottom
        text={t('continue')}
        onClick={() => {
          scrollToWidget();
          setCurrentStep(currentStep + 1);
        }}
      ></BlockButton>
    </div>
  );
}
