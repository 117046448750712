import styled from 'styled-components'

export const StyledOnboarding = styled.div`
  margin: 0 auto;
  min-height: 90vh;
  padding: 0;
  width: 100%;

  .container-fullwidth {
    width: 100%;
  }

  .go-back {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9999;
    box-shadow: #cccccc 0 0 16px;
    transition: all 0.3s;

    svg {
      height: 20px;
      width: 20px;
      fill: #111;
    }

    &:hover {
      transform: rotate(360deg);
    }

    @media (min-width: 720px) {
      width: 60px;
      height: 60px;
      top: 5%;
      left: 5%;

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }

  .onboarding-headline {
    font-weight: 700;
    display: block;
    font-family: 'clever-german', serif;
    font-size: 36px;
    margin: 30px 10% 20px;
    color: #111;
    text-align: center;
    line-height: 1.2;
  }

  .onboarding-smallline {
    font-weight: 700;
    display: block;
    font-family: 'clever-german', serif;
    font-size: 27px;
    margin: 30px 10% 20px;
    color: #111;
    text-align: center;
    line-height: 1.2;
  }

  .onboarding-text {
    display: block;
    text-align: center;
    margin: 0 3% 20px;
    max-width: 520px;
    font-size: 18px;
  }

  .story-text {
    color: rgb(46, 59, 75);
    font-family: now, sans-serif;
    font-size: 16px;
    text-align: center;
    line-height: 1.4;
    margin: 20px 10% 10px;
    width: 90%;
    max-width: 740px;
    z-index: 99;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: 375px) {
      font-size: 14px;
    }

    .headline {
      font-family: 'clever-german', serif;
      font-size: 18px;
      line-height: 1.2;
      font-weight: 700 !important;
      color: #111;
      color: #c19c7b;
      margin: 0 0 10px;
    }

    b {
      color: #c19c7b;
      font-family: 'breathing', sans-serif;
      font-size: 30px;
      line-height: 1.2;
      position: relative;
      top: 10px;
      left: 4px;
      margin-bottom: 20px;
      display: block;

      @media only screen and (max-width: 375px) {
        font-size: 24px;
      }
    }

    h6 {
      font-size: 12px;
    }

    .tell-me-more {
      margin-top: 20px;
      background-color: #fff;
      border-radius: 10px;
      font-size: 14px;
      display: inline-block;
      padding: 10px 20px;
      color: #7f8aa5;
      border-left: 1px solid #eee;
    }
  }
`
