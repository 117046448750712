import { AuthContext } from 'context/AuthContext'
import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_benefits.json'
import * as premium from 'assets/lottie/animation_onboarding_premium.json'
import BlockButton from 'components/buttons/BlockButton'
import styled from 'styled-components'
import { useAPI } from 'context/APIcontext'

const StyledWelcomeStep = styled.div`
  max-width: 720px;
  width: 100%;

  .onboarding-headline {
    margin-bottom: 10px;
  }

  .onboarding-text {
    margin: 0 3% 20px;
    font-size: 17px;
  }

  .onboarding-logo {
    margin: 20px 3% 0;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;

    img {
      width: 80px;
    }
  }

  .login-options {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    &.login-options--column {
      flex-direction: column;
      text-align: center;

      .login-options-item {
        margin-top: 20px;
      }

      .fake-link {
        position: relative;
        font-weight: bold;
        display: inline;
        cursor: pointer;

        &:after {
          content: '';
          height: 2px;
          background-color: var(--art-colors-bg);
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -12px;
        }
      }
    }

    a {
      color: #333;
      text-decoration: none;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: center;

      input[type='checkbox'] {
        margin-right: 10px;
      }
    }
  }

  .input-box-error {
    background-color: red;
    color: #fff;
    margin: 0 3% 10px;
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    //background-image: linear-gradient(95deg, #cfabff, #e77d7d 100%);
    background-color: red;

    @media (min-width: 720px) {
      margin: 0 0 10px;
    }
  }
`

const LoginStep = ({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  email: propEmail,
  setEmail: setPropEmail,
  loginState,
  onLogin,
}) => {
  const { t } = useTranslation('common')

  const { setLoginToken, setUserID, setUserDisplayName } = useAPI()

  const [loginMode, setLoginMode] = useState(loginState || 'register')
  const [email, setEmail] = useState('marcgraf2@gmail.com')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('J%Pd$&xa*&B%%2Ux&Z')
  const [repeatPassword, setRepeatPassword] = useState('J%Pd$&xa*&B%%2Ux&Z')

  useEffect(() => {
    validateEmail(email)
  }, [email])

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: premium,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  })

  const handleRegister = async (e) => {
    //e.preventDefault()
    try {
      const response = await fetch('https://tinyaffirmation.com/wp-json/custom/v1/register/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      })
      const data = await response.json()
      if (response.ok) {
        // Automatically log the user in after registration
        handleLogin()
      } else {
        //alert('Error during registration: ' + data.message)
        if (data.code === 'email_exists') {
          setEmailError(t(`error.${data.code}`))
        }
      }
    } catch (error) {
      console.error('Error registering:', error)
    }
  }

  const handleLogin = async () => {
    // e.preventDefault()
    try {
      const response = await fetch('https://tinyaffirmation.com/wp-json/jwt-auth/v1/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies for CORS
        body: JSON.stringify({ username: email, password }),
      })

      const data = await response.json()

      if (response.ok && data.token) {
        // Store the token in localStorage or cookies
        localStorage.setItem('authToken', data.token)

        console.log('User logged in:', data.token)
        // Now send the token to the backend to set WordPress cookies
        const setCookieResponse = await fetch(
          'https://tinyaffirmation.com/wp-json/custom/v1/set-cookie',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: data.token }),
            credentials: 'include',
          },
        )

        if (setCookieResponse.ok) {
          const data = await setCookieResponse.json()
          setLoginToken(data)

          onLogin([data.user_id, data.display_name])
        } else {
          console.error('Failed to log in to WordPress backend.')
          setEmailError(t('error.login-failed'))
        }
      } else {
        // Handle login failure
        console.error('Login failed', data)
        setEmailError(t('error.login-failed'))
      }
    } catch (error) {
      console.error('Error logging in:', error)
      setEmailError(t('error.login-failed'))
    }
  }

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const sendEmailToAPI = async () => {
    const payload = {
      email: email,
      listIds: [9],
      attributes: {
        // Include all required attributes here
      },
    }
    const headers = {
      'Content-Type': 'application/json',
      'api-key': 'your-api-key-here',
    }
    try {
      const response = await fetch('https://api.sendinblue.com/v3/contacts', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      })
      const data = await response.json()
      console.log(data)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <StyledWelcomeStep>
      <div className="animated fadeIn">
        {loginMode === 'register' && (
          <>
            <div className="onboarding-logo">
              <img
                src="https://tinyaffirmation.com/wp-content/uploads/2024/03/tinyaffirmation-logo-gold.png"
                alt="Site Logotype"
              />
            </div>
            <div className="animated fadeIn">
              <Lottie
                options={defaultOptions}
                height={200}
                width={200}
                isStopped={defaultOptions.isStopped}
                isPaused={defaultOptions.isPaused}
              />
            </div>
            <span className="onboarding-headline animated fadeIn">
              {t('login-step-register-headline')}
            </span>
            <span className="onboarding-text animated fadeIn">{t('login-step-text')}</span>

            {emailError && <div className="input-box-error animated shake">{emailError}</div>}
            <div className="input-box animated fadeIn" style={{ zIndex: 9999 }}>
              <div className="input-box-input-wrapper">
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onClick={() => setEmailError('')}
                  className={`input-box-input ${emailError ? 'is-error' : ''}`}
                  placeholder={t('welcome-page.login-step-email-input-label')}
                />
                <label htmlFor="email ">{t('welcome-page.login-step-email-input-label')}</label>
              </div>

              <div className="input-box-input-wrapper">
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onClick={() => setPassword('')}
                  className={`input-box-input`}
                  placeholder={t('welcome-page.login-step-password-input-label')}
                />
                <label htmlFor="email">{t('welcome-page.login-step-password-input-label')}</label>
              </div>

              <BlockButton
                noMargin
                disabled={!email || !validateEmail(email)}
                text={t('welcome-page.login-step-register-button')}
                onClick={() => {
                  if (email && validateEmail(email)) {
                    handleRegister()
                  } else {
                    setEmailError(true)
                  }
                }}
              ></BlockButton>

              <div
                className="login-options login-options--column"
                onClick={() => {
                  setLoginMode('login')
                }}
              >
                <div className="login-options-item">
                  <div className="fake-link" href="/reset-password/">
                    {t('welcome-page.already-have-an-account')}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {loginMode === 'login' && (
          <>
            <div className="onboarding-logo">
              <img
                src="https://tinyaffirmation.com/wp-content/uploads/2024/03/tinyaffirmation-logo-gold.png"
                alt="Site Logotype"
              />
            </div>
            <div className="animated fadeIn">
              <Lottie
                options={defaultOptions}
                height={200}
                width={200}
                isStopped={defaultOptions.isStopped}
                isPaused={defaultOptions.isPaused}
              />
            </div>
            <span className="onboarding-headline animated fadeIn">
              {t('login-step-login-headline')}
            </span>
            <span className="onboarding-text animated fadeIn">{t('login-step-text')}</span>

            {emailError && <div className="input-box-error animated shake">{emailError}</div>}
            <div className="input-box animated fadeIn" style={{ zIndex: 9999 }}>
              <div className="input-box-input-wrapper">
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onClick={() => setEmailError('')}
                  className={`input-box-input ${emailError ? 'is-error' : ''}`}
                  placeholder={t('welcome-page.login-step-email-input-label')}
                />
                <label htmlFor="email">{t('welcome-page.login-step-email-input-label')}</label>
              </div>
              <div className="input-box-input-wrapper">
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onClick={() => setPassword('')}
                  className={`input-box-input`}
                  placeholder={t('welcome-page.login-step-password-input-label')}
                />
                <label htmlFor="email">{t('welcome-page.login-step-password-input-label')}</label>
              </div>
              {/*<div className="input-box-input-wrapper">
              <input
                id="repeat-password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onClick={() => setPassword('')}
                className={`input-box-input`}
                placeholder={t('welcome-page.login-step-password-input-label-repeat')}
              />
              <label htmlFor="email">
                {t('welcome-page.login-step-password-input-label-repeat')}
              </label>
            </div>*/}

              {/*<div className="login-options">
                      <div className="login-options-item">
                        <label>
                          <input type="checkbox" name="rememberme" value="1" checked />
                          <span>{t('welcome-page.stay-logged-in')}</span>
                        </label>
                      </div>
                      <div className="login-options-item">
                        <a href="/reset-password/">{t('welcome-page.forgot-password')}</a>
                      </div>
                </div>*/}

              <BlockButton
                noMargin
                disabled={!email || !validateEmail(email)}
                text={t('welcome-page.login-step-login-button')}
                onClick={() => {
                  if (email && validateEmail(email)) {
                    handleLogin()
                  } else {
                    setEmailError(true)
                  }
                }}
              ></BlockButton>

              <div
                className="login-options login-options--column"
                onClick={() => {
                  setCurrentStep(1)
                }}
              >
                <div className="login-options-item">
                  <div className="fake-link" href="/reset-password/">
                    {t('welcome-page.no-account')}
                  </div>
                </div>
              </div>

              <div
                className="login-options login-options--column"
                onClick={() => {
                  setLoginMode('forgot-password')
                }}
              >
                <div className="login-options-item">
                  <div className="fake-link" href="/reset-password/">
                    {t('welcome-page.forgot-passwort')}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </StyledWelcomeStep>
  )
}

export default LoginStep
