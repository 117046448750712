export default function generateOverviewData(planetObject, textKey, topicKey, t) {
  return {
    title: `${t(planetObject.name.replace(/ /g, '_').toLowerCase())}: ${t(
      planetObject.sign.name.replace(/ /g, '_').toLowerCase(),
    )} - ${t(planetObject.house.number + '_house')}`,
    key: `${planetObject.name.replace(/ /g, '_').toLowerCase()} in ${planetObject.sign.name
      .replace(/ /g, '_')
      .toLowerCase()} in house ${planetObject.house.number}`,
    text: t(textKey),
    topic: encodeURIComponent(topicKey),
  };
}
