import PageTitle from 'components/PageTitle'
import StyledSlider from 'components/StyledSlider'
import { BASE_URL } from 'constants/URLS'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledPickCardOverlay = styled.div`
  padding-top: 60px;
  width: 100%;
  height: 100%;

  .tarot-card-picker {
    padding: 0 10px;

    img {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      overflow: hidden;
    }
  }
`

export default function PickCardOverlay({ index, currentSpread, tarotCards, onCardPicked }) {
  const { t } = useTranslation('common')

  const [availableCards, setAvailableCards] = useState(tarotCards)

  var settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    //variableWidth: true,
    swipeToSlide: true,
    spacing: 0,
  }

  useEffect(() => {
    let availableCards = tarotCards.filter(
      (card) => !currentSpread.cards.some((spreadCard) => spreadCard.selectedCard === card),
    )
    availableCards = shuffle(availableCards)

    setAvailableCards(availableCards)
  }, [])

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }

  return (
    <StyledPickCardOverlay>
      <PageTitle
        title={t('spreads.pick-a-card-headline')}
        text={t('spreads.pick-a-card-text')}
      ></PageTitle>

      <StyledSlider {...settings}>
        {availableCards.map((singleTarotCard, cardIndex) => (
          <div className="tarot-card-picker" key={`card-${cardIndex}`}>
            <img
              src={`${BASE_URL}/api/images/tarotcards/back.jpg`}
              alt={`Card ${cardIndex}`}
              onClick={() => {
                onCardPicked(singleTarotCard, index)
              }}
            />
          </div>
        ))}
      </StyledSlider>
    </StyledPickCardOverlay>
  )
}
