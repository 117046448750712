import React, { useState, useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import PageTitle from 'components/PageTitle'
import { API_URL, ASSETS_URL, BASE_URL, MOON_EXPLANATIONS_API } from 'constants/URLS'
import { calculateScoring } from './MoonScoreHelper'
import { useAPI } from 'context/APIcontext'
import LockClosedIcon from 'components/SVG/LockClosedIcon'
import SectionHeader from 'components/SectionHeader'
import TagCloud from 'components/TagCloud'
import { getLanguage } from 'helper/getLanguage'
import LandingPage from 'pages/LandingPage/LandingPage'
import MoonCalendarDetail from './MoonCalendarDetail/MoonCalendarDetail'
import Modal from 'components/Modal'
import OnboardingPage from 'pages/OnboardingPage/OnboardingPage'
import AstrologyEvents from 'components/AstrologyEvents'
import LoadingState from 'components/LoadingState'

const StyledMoonCalendar = styled.div`
  margin-top: 80px;

  select {
    border: 1px solid #ddd;
    border-radius: 10px;
    max-width: 720px;
    margin: 0 3% 20px;
    width: 94%;

    @media (min-width: 720px) {
      margin: 0 auto 20px;
      width: 100%;
    }
  }

  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #eee;
    margin: 20px 3% 20px;
    padding-top: 20px;

    @media (min-width: 720px) {
      margin: 0 auto 20px;
      width: 100%;
    }

    .month-btn {
      padding: 5px 10px;
      cursor: pointer;
      font-size: 15px;
      transition: all 0.3s;
      border: 2px solid #c19c7b;
      color: #c19c7b;
      border-radius: 10px;
      font-weight: 700;
      font-family: 'clever-german', serif;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.1;
      }
    }

    .month-name {
      line-height: 1.2;
      font-size: 24px;
      font-weight: 700;
      padding: 0;
      color: #111;
      text-align: center;
      font-family: 'clever-german', serif;
    }
  }

  .weekdays {
    margin: 0 3%;
    display: flex;
  }

  .weekday {
    flex: 1;
    text-align: center;
    font-family: 'clever-german', serif;
    font-weight: 700;
    font-size: 18px;
  }

  .days {
    margin: 0 3%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    //border-left: 1px solid #ddd;
    //border-top: 1px solid #ddd;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;

    @media (min-width: 768px) {
    }
  }

  .day {
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    font-size: 8px;
    border-radius: 10px;
    //border-bottom: 1px solid #ddd;
    //border-right: 1px solid #ddd;

    &.is-current-day {
      background-color: #c19c7b;
      color: #fff;

      .single-zodiac {
        color: #fff;
      }

      .moon-day {
        color: #fff;
      }
    }

    @media (min-width: 768px) {
      padding: 5px;
      margin: 10px;
    }

    .moon-score {
      text-indent: -9999px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 4px auto 0;

      &.good {
        background-color: green;
      }

      &.semi {
        background-color: yellow;
      }

      &.bad {
        background-color: red;
      }
    }

    .single-zodiac {
      display: block;
    }

    .date {
      font-weight: 700;
      font-size: 14px;
      font-family: 'clever-german', serif;

      @media (min-width: 768px) {
        font-size: 18px;
      }
    }

    .moon-zodiac {
      color: #111;
      padding: 2px 0 6px;

      .single-zodiac {
        margin-top: 10px;
        font-weight: 700;
      }

      .single-zodiac-emoji {
        margin-bottom: 4px;
        font-size: 20px;
      }
    }

    .moon-day {
      font-size: 14px;
      font-weight: 700;
      border-radius: 10px;
      color: #111;
      margin: 3%;
    }

    .moon-phase {
      display: none;
    }

    .moon-image {
      height: 40px;
      width: 40px;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      display: inline-block;
    }

    .moon-zodiac {
      line-height: 1.2;
    }

    &.non-current-month {
      display: none;
      opacity: 0.1;

      @media (min-width: 768px) {
        display: block;
      }
    }
  }

  .day.empty {
    visibility: hidden;
  }
`

const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const getMoonPhase = (date, moonData) => {
  if (!moonData || moonData.length === 0) return '' // Return a default value if moonData is not available
  const moon = moonData.find((moon) => moon.date === date.format('D-M-YYYY'))
  return moon ? moon.phase : ''
}

const getMoonZodiac = (date, moonData) => {
  if (!moonData || moonData.length === 0) return [] // Return a default value if moonData is not available
  const moon = moonData.find((moon) => moon.date === date.format('D-M-YYYY'))
  return moon ? moon.zodiac : []
}

const getMoonImage = (date, moonData) => {
  if (!moonData || moonData.length === 0) return '' // Return a default value if moonData is not available
  const moon = moonData.find((moon) => moon.date === date.format('D-M-YYYY'))
  return moon ? moon.image : ''
}

const getMoonDetails = (date, moonData) => {
  if (!moonData || moonData.length === 0) return '' // Return a default value if moonData is not available
  const moon = moonData.find((moon) => moon.date === date.format('D-M-YYYY'))
  return moon ? moon : {}
}

function getMoonExplanation(moonDay, moonZodiac, zodiacSign, language) {
  const moonExplanationsUrl = `${MOON_EXPLANATIONS_API}?moonDay=${encodeURIComponent(
    moonDay,
  )}&moonZodiac=${encodeURIComponent(moonZodiac)}&userZodiac=${zodiacSign}&language=${language}`

  return fetch(moonExplanationsUrl).then((response) => response.json())
}

const MoonCalendar = () => {
  const { t } = useTranslation('common')
  const { userData, isPremium } = useAPI()
  const [moonData, setMoonData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentDate, setCurrentDate] = useState(dayjs())
  const [moonDoItems, setMoonDoItems] = useState([])
  const [selectedMoonDoItem, setSelectedMoonDoItem] = useState()

  const [currentMoonDetails, setCurrentMoonDetails] = useState({})
  const [currentMoonExplanation, setCurrentMoonExplanation] = useState({})
  const [selectedDate, setSelectedDate] = useState(dayjs())
  const [moonChangeTime, setMoonChangeTime] = useState(null)

  const [showLandingPage, setShowLandingPage] = useState(false)
  const [showMoonDetail, setshowMoonDetail] = useState(false)

  const [activeFilter, setActiveFilter] = useState('moonDay')
  const [allFilers] = useState([
    'moonDay',
    'zodiac',
    'nutrition',
    'planting',
    'selfCare',
    'travelling',
    'career',
    'spirituality',
    'health',
    'beauty',
    'completion',
    'romance',
    'bigDecision',
    'socialActivity',
  ])

  const [allFilersObj] = useState([
    { title: 'moonDay', isPremium: false, icon: '🌙' },
    { title: 'zodiac', isPremium: false, icon: '⛎' },
    { title: 'nutrition', isPremium: true, icon: '🍏' },
    { title: 'planting', isPremium: true, icon: '🌱' },
    { title: 'selfCare', isPremium: true, icon: '🧘' },
    { title: 'travelling', isPremium: true, icon: '🚗' },
    { title: 'career', isPremium: true, icon: '💼' },
    { title: 'spirituality', isPremium: true, icon: '🧘' },
    { title: 'health', isPremium: true, icon: '🏥' },
    { title: 'beauty', isPremium: true, icon: '💄' },
    { title: 'completion', isPremium: true, icon: '🏁' },
    { title: 'romance', isPremium: true, icon: '💑' },
    { title: 'bigDecision', isPremium: true, icon: '🤔' },
    { title: 'socialActivity', isPremium: true, icon: '👫' },
  ])
  const [activeFilterObj, setActiveFilterObj] = useState(allFilersObj[0])

  useEffect(() => {
    const fetchMoonData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/moon/short.json`)
        const data = await response.json()
        setMoonData(data)
        setIsLoading(false)

        const currentMoonDetails = getMoonDetails(selectedDate, data)
        setCurrentMoonDetails(currentMoonDetails)

        const currentMoonExplanation = await getMoonExplanation(
          currentMoonDetails.moonDay,
          currentMoonDetails.zodiac[0],
          userData.zodiacSign || 'none',
          getLanguage(),
        )
        setCurrentMoonExplanation(currentMoonExplanation[0])
      } catch (error) {}
    }

    fetchMoonData()
  }, [])

  useEffect(() => {}, [currentMoonDetails])

  const handlePrevMonth = () => {
    if (currentDate.isAfter(dayjs(), 'month')) {
      setCurrentDate((prevDate) => prevDate.subtract(1, 'month'))
    }
  }

  const handleChangeFilter = (filter) => {
    const activeFilter = allFilersObj.find((f) => f.title === filter)

    if (isPremium) {
      setActiveFilter(activeFilter)
      return
    }

    if (filter == 'moonDay' || filter == 'zodiac') {
      setActiveFilter(activeFilter)
      return
    }

    setShowLandingPage(true)
  }

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => prevDate.add(1, 'month'))
  }

  const renderDays = () => {
    const daysInMonth = currentDate.daysInMonth()
    const firstDayOfMonth = currentDate.startOf('month').day()

    // Calculate the offset, adjusting to make Monday the start of the week
    const offset = (firstDayOfMonth + 6) % 7

    // Create empty cells for days before the first day of the current month
    const emptyDays = Array.from({ length: offset }, (_, index) => (
      <div key={`empty-${index}`} className="day empty"></div>
    ))

    // Create the days for the current month
    const currentMonthDays = Array.from({ length: daysInMonth }, (_, index) => {
      const date = dayjs(currentDate).date(index + 1)
      return renderDay(date, true)
    })

    // Combine empty cells and current month days
    return [...emptyDays, ...currentMonthDays]
  }

  const mapZodiacToEmoji = (text) => {
    switch (text) {
      case 'taurus':
        return '♉'
      case 'aries':
        return '♈'
      case 'gemini':
        return '♊'
      case 'cancer':
        return '♋'
      case 'leo':
        return '♌'
      case 'virgo':
        return '♍'
      case 'libra':
        return '♎'
      case 'scorpio':
        return '♏'
      case 'sagittarius':
        return '♐'
      case 'capricorn':
        return '♑'
      case 'aquarius':
        return '♒'
      case 'pisces':
        return '♓'
      default:
        return text
    }
  }

  const renderDay = (date, isCurrentMonth) => {
    const moonZodiac = getMoonZodiac(date, moonData)
    const moonPhase = getMoonPhase(date, moonData)
    const image = getMoonImage(date, moonData)
    const details = getMoonDetails(date, moonData)

    const zodiac =
      userData && userData.userProfile && userData.userProfile.zodiacSign
        ? userData.userProfile.zodiacSign
        : 'Unknown'
    const position = details && details.position ? details.position : 'Unknown'

    const moonScored = calculateScoring({
      date: date.format('D-M-YYYY'),
      moonPhase: moonPhase,
      moonSign: moonZodiac,
      position: position,
      zodiac: zodiac,
    })

    return (
      <div
        onClick={() => {
          setSelectedDate(date)
          setCurrentMoonDetails(details)
        }}
        key={date.format('YYYY-MM-DD')}
        className={`day ${!isCurrentMonth ? 'non-current-month' : ''} ${
          selectedDate.isSame(date, 'day') ? 'is-current-day' : ''
        }`}
      >
        <div className="date">{date.date()}</div>

        <div className="moon-phase">{t(moonPhase)}</div>

        <div
          className="moon-image"
          style={{
            backgroundImage: `url(${ASSETS_URL}/img/moon/${image}.webp)`,
          }}
        ></div>
        {activeFilterObj.title == 'zodiac' && (
          <div className="moon-zodiac">
            {moonZodiac.map((zodiac, zodiacIndex) => (
              <span className="single-zodiac" key={`${zodiac}-${zodiacIndex}`}>
                <div className="single-zodiac-emoji">{mapZodiacToEmoji(zodiac)}</div>
                {t(zodiac)}
              </span>
            ))}
          </div>
        )}
        {activeFilterObj.title == 'moonDay' && (
          <div className="moon-day">{`${details.moonDay}.`}</div>
        )}
        {activeFilterObj.title == 'nutrition' && (
          <div className={`moon-score ${moonScored.nutrition}`}>{t(moonScored.nutrition)}</div>
        )}
        {activeFilterObj.title == 'planting' && (
          <div className={`moon-score ${moonScored.planting}`}>{t(moonScored.planting)}</div>
        )}
        {activeFilterObj.title == 'selfCare' && (
          <div className={`moon-score ${moonScored.selfCare}`}>{t(moonScored.selfCare)}</div>
        )}
        {activeFilterObj.title == 'travelling' && (
          <div className={`moon-score ${moonScored.travelling}`}>{t(moonScored.travelling)}</div>
        )}
        {activeFilterObj.title == 'career' && (
          <div className={`moon-score ${moonScored.career}`}>{t(moonScored.career)}</div>
        )}
        {activeFilterObj.title == 'spirituality' && (
          <div className={`moon-score ${moonScored.spirituality}`}>
            {t(moonScored.spirituality)}
          </div>
        )}
        {activeFilterObj.title == 'health' && (
          <div className={`moon-score ${moonScored.health}`}>{t(moonScored.health)}</div>
        )}
        {activeFilterObj.title == 'beauty' && (
          <div className={`moon-score ${moonScored.beauty}`}>{t(moonScored.beauty)}</div>
        )}
        {activeFilterObj.title == 'completion' && (
          <div className={`moon-score ${moonScored.completion}`}>{t(moonScored.completion)}</div>
        )}
        {activeFilterObj.title == 'romance' && (
          <div className={`moon-score ${moonScored.romance}`}>{t(moonScored.romance)}</div>
        )}
        {activeFilterObj.title == 'bigDecision' && (
          <div className={`moon-score ${moonScored.bigDecision}`}>{t(moonScored.bigDecision)}</div>
        )}
        {activeFilterObj.title == 'socialActivity' && (
          <div className={`moon-score ${moonScored.socialActivity}`}>
            {t(moonScored.socialActivity)}
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <StyledMoonCalendar>
        <PageTitle title={t('moon-calendar.title')} text={t('moon-calendar.text')} />

        {/*selectedMoonDoItem && (
            <TaskCard
              title={`${selectedMoonDoItem.headline}`}
              text={`${selectedMoonDoItem.text}`}
              topic={`${selectedMoonDoItem.topic}`}
              onClick={() => {}}
              isDone={false}
            ></TaskCard>
          )*/}

        <select onChange={(e) => handleChangeFilter(e.target.value)}>
          {allFilersObj.map((filter, filterIndex) => (
            <option key={`${filterIndex}-${filter.title}`} value={filter.title}>
              {filter.isPremium && !isPremium ? '🔒' : filter.icon}
              &nbsp;
              {t(`article-tags.${filter.title}`)}
            </option>
          ))}
        </select>

        {/*  <div className="tag-cloud">
          {allFilersObj.map((filter, filterIndex) => (
            <div
              onClick={() => {
                if (filter.isPremium && !isPremium) {
                  setShowLandingPage(true);
                  return;
                } else {
                  setActiveFilterObj(filter);
                }
              }}
              className={`tag ${activeFilterObj.title === filter.title ? 'is-active' : ''} ${
                filter.isPremium && !isPremium ? 'is-disabled' : 'is-enabled'
              }`}
              key={`${filterIndex}-${filter.title}`}
            >
              <div className="tag-icon">{filter.icon}</div>
              {filter.isPremium && !isPremium && <LockClosedIcon />}
              {t(`article-tags.${filter.title}`)}
            </div>
          ))}
        </div>*/}

        {isLoading ? (
          <LoadingState
            title={t('affirmation.loading-headline')}
            text={t('affirmation.loading-text')}
          />
        ) : (
          <>
            <div className="calendar-header">
              <div
                className={`month-btn ${currentDate.isAfter(dayjs(), 'month') ? '' : 'disabled'}`}
                onClick={handlePrevMonth}
              >
                {t('previous')}
              </div>
              <div className="month-name">
                {`${t(currentDate.format('MMMM').toLowerCase())} ${currentDate.format('YYYY')}`}
              </div>
              <div className="month-btn" onClick={handleNextMonth}>
                {t('next')}
              </div>
            </div>

            <div className="weekdays">
              {weekdays.map((day) => (
                <div key={day} className="weekday">
                  {day}
                </div>
              ))}
            </div>
            <div className="days">{renderDays()}</div>
          </>
        )}

        <AstrologyEvents date={selectedDate} mode={'month'}></AstrologyEvents>
      </StyledMoonCalendar>

      {showLandingPage && (
        <Modal
          onClose={() => {
            setShowLandingPage(false)
          }}
        >
          <OnboardingPage onClose={() => setShowLandingPage(false)}></OnboardingPage>
        </Modal>
      )}

      {showMoonDetail && (
        <Modal
          onClose={() => {
            setshowMoonDetail(false)
          }}
        >
          <MoonCalendarDetail
            currentMoonDetails={currentMoonDetails}
            currentMoonExplanation={currentMoonExplanation}
            goBack={() => {
              setshowMoonDetail(false)
            }}
          ></MoonCalendarDetail>
        </Modal>
      )}
    </>
  )
}

export default MoonCalendar
