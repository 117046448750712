import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import PageTitle from 'components/PageTitle'
import { API_URL, ARTICLES_DATA_API, SOUNDS_DATA_API } from 'constants/URLS'
import Modal from 'components/Modal'
import { getLanguage } from 'helper/getLanguage'
import StoryPage from 'pages/StoryPage/StoryPage'
import { useAPI } from 'context/APIcontext'
import LockClosedIcon from 'components/SVG/LockClosedIcon'
import ContentCard from 'components/ContentCard'
import SectionHeader from 'components/SectionHeader'
import WisdomListPage from 'pages/WisdomPage/WisdomListPage'
import { shuffleArray } from './../../helper/shuffleArray'
import ContentCardBig from 'components/ContentCardBig'
import LandingPage from 'pages/LandingPage/LandingPage'
import StyledSlider from 'components/StyledSlider'
import OnboardingPage from 'pages/OnboardingPage/OnboardingPage'
import CoachingDetail from './CoachingDetail'
import TrainingPlanDetail from './TrainingPlanDetail'
import TrainingPlanStarted from './TrainingPlanStarted'
import TrainingPlanList from './TrainingPlanList'

const StyledCoachingSessions = styled.div``

export default function CoachingSessions() {
  const { t } = useTranslation('common')

  const { isPremium, coachingSessions, getTodaysCoachings } = useAPI()

  const [mappedCoachingSessions, setMappedCoachingSessions] = useState(null)
  const [selectedList, setSelectedList] = useState(null)
  const [selectedSession, setSelectedSession] = useState(null)
  const [selectedTrainingPlan, setSelectedTrainingPlan] = useState(null)
  const [showLandingPage, setShowLandingPage] = useState(false)
  const [isLoadingDone, setIsLoadingDone] = useState(false)
  const [showPlanStartedPage, setShowPlanStartedPage] = useState(false)
  const [trainingPlansOfTheDay, setTrainingPlansOfTheDay] = useState(null)
  const [sessionsOfTheDay, setSessionsOfTheDay] = useState(null)

  const [showAllTrainingPlans, setShowAllTrainingPlans] = useState(false)
  const [showAllCategories, setShowAllCategories] = useState(false)

  useEffect(() => {
    if (coachingSessions) {
      coachingSessions.oldTags = coachingSessions.tags

      const sessionMap = new Map(
        coachingSessions.sessions_v3.map((session) => [session.id, session]),
      )

      coachingSessions.collections.forEach((collection) => {
        collection.sessionDetails = collection.sessions
          .map((sessionId) => sessionMap.get(sessionId))
          .filter(Boolean) // Filter out undefined values
      })

      // Step 1: Iterate over the tags to check the length of sessionDetails
      let combinedSessions = []
      let newTags = []

      // First loop to create the sessionDetails array and titles for each tag
      coachingSessions.tags.forEach((tag) => {
        tag.sessionDetails = []
        tag.title = tag.label

        coachingSessions.sessions_v3.forEach((session) => {
          if (session.tags.includes(tag.id)) {
            tag.sessionDetails.push(session)
          }
        })

        // Collect tags with 10 or fewer entries
        if (tag.sessionDetails.length <= 10) {
          combinedSessions = combinedSessions.concat(tag.sessionDetails)
        } else {
          newTags.push(tag) // Keep tags with more than 10 entries
        }
      })

      // Step 2: Create a new combined tag for the tags with 10 or fewer entries
      if (combinedSessions.length > 0) {
        newTags.push({
          id: 'combined', // Unique ID for the new combined tag
          label: 'Combined Sessions', // Label for the new combined tag
          sessionDetails: combinedSessions,
          title: 'Combined Sessions', // Title for the new combined tag
        })
      }

      // Step 3: Assign the new tags array to coachingSessions.tags
      coachingSessions.newTags = newTags

      getTodaysCoachings().then((todaysCoachings) => {
        setSessionsOfTheDay(todaysCoachings.sessions)
        setTrainingPlansOfTheDay(todaysCoachings.trainingPlans)
      })

      const categories = ['morning', 'evening', 'daytime', 'sos']
      categories.forEach((category) => {
        coachingSessions[category].forEach((entry) => {
          entry.sessionDetails = getSessionDetailsById(entry.session_id)
        })
      })

      // First loop to create the sessionDetails array and titles for each tag
      coachingSessions.tags.forEach((tag) => {
        tag.sessionDetails = []
        tag.title = tag.label

        coachingSessions.sessions_v3.forEach((session) => {
          if (session.tags.includes(tag.id)) {
            tag.sessionDetails.push(session)
          }
        })
      })

      coachingSessions.explore_categories.forEach((category) => {
        category.sessionDetails = getSessionsByTagId(category.tag_id)
      })

      setMappedCoachingSessions(coachingSessions)
      setIsLoadingDone(true)
    }
  }, [])

  function getSessionDetailsById(sessionId) {
    return coachingSessions.sessions_v3.find((session) => session.id === sessionId)
  }

  function getSessionsByTagId(tagId) {
    return coachingSessions.sessions_v3.filter((session) => session.tags.includes(tagId))
  }

  var sliderSettings = {
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.3,
        },
      },
    ],
  }

  return (
    <StyledCoachingSessions>
      {isLoadingDone && (
        <>
          {/*<SectionHeader
            isLeft={true}
            title={t(`recommended-courses`)}
            showMoreText={`${t('show-all')} (${mappedCoachingSessions.training_plans.length})`}
            onShowMore={() => setShowAllTrainingPlans(true)}
          ></SectionHeader>

          <div className="animated fadeIn">
            {trainingPlansOfTheDay && (
              <StyledSlider {...sliderSettings} hasMaxWidth={true}>
                {trainingPlansOfTheDay.map((trainingPlan, index) => (
                  <ContentCardBig
                    key={trainingPlan.id} // Change here
                    title={trainingPlan.title}
                    text={`${trainingPlan.description}`}
                    image={`${API_URL}/api/images/watercolor/${encodeURIComponent(
                      trainingPlan.title.toLowerCase().replace(/ /g, '_'),
                    )}.jpg`}
                    onClick={() => {
                      setSelectedTrainingPlan(trainingPlan)
                    }}
                  ></ContentCardBig>
                ))}
              </StyledSlider>
            )}
                  </div>*/}

          <SectionHeader isLeft={true} title={t(`recommended-sessions`)}></SectionHeader>

          <div className="animated fadeIn">
            <StyledSlider {...sliderSettings} hasMaxWidth={true}>
              {sessionsOfTheDay &&
                sessionsOfTheDay.map((session, index) => (
                  <ContentCardBig
                    key={session.id} // Change here
                    title={session.title}
                    text={`${session.description}`}
                    isLocked={!isPremium}
                    image={`${API_URL}/api/images/watercolor/${encodeURIComponent(
                      session.title.toLowerCase().replace(/ /g, '_'),
                    )}.jpg`}
                    onClick={() => {
                      if (!isPremium) {
                        setShowLandingPage(true)
                      } else {
                        setSelectedSession(session)
                      }
                    }}
                  ></ContentCardBig>
                ))}
            </StyledSlider>
          </div>

          {/*
          <SectionHeader
            isLeft={true}
            title={t(`discover`)}
            onShowMore={() => setShowAllCategories(true)}
            showMoreText={`${t('show-all')} (${mappedCoachingSessions.tags.length})`}
          ></SectionHeader>

          <div className="tag-cloud is-left animated fadeIn">
            {coachingSessions.overall_categories &&
              coachingSessions.overall_categories.map((category, index) => (
                <>
                  {category.sessions.length > 1 && (
                    <div
                      key={category.title + index} // Change here
                      onClick={() => {
                        setSelectedList({
                          title: category.title,
                          list: category.sessions,
                          listTitleProp: 'title',
                          listTextProp: 'description',
                          listImageProp: 'title',
                          generatedImage: true,
                        })
                      }}
                      className={`tag`}
                    >
                      <div className="tag-icon"></div>
                      {`${category.title}`}
                    </div>
                  )}
                </>
              ))}
          </div> */}

          {coachingSessions.overall_categories &&
            coachingSessions.overall_categories.map((category, index) => (
              <div key={category.title + index}>
                {category.sessions.length > 1 && (
                  <div className="animated fadeIn">
                    <SectionHeader
                      isLeft={true}
                      title={category.title}
                      onShowMore={() =>
                        setSelectedList({
                          title: category.title,
                          list: category.sessions,
                          listTitleProp: 'title',
                          listTextProp: 'description',
                          listImageProp: 'title',
                          generatedImage: true,
                          isLocked: true,
                        })
                      }
                      showMoreText={`${t('show-all')} (${category.sessions.length})`}
                    ></SectionHeader>
                    {category.sessions &&
                      category.sessions.slice(0, 3).map((session, sessionIndex) => (
                        <ContentCard
                          key={session.id} // Change here
                          title={session.title}
                          text={session.description}
                          isLocked={!isPremium}
                          onClick={() => {
                            if (!isPremium) {
                              setShowLandingPage(true)
                            } else {
                              setSelectedSession(session)
                            }
                          }}
                          image={`${API_URL}/api/images/watercolor/${encodeURIComponent(
                            session.title.toLowerCase().replace(/ /g, '_'),
                          )}.jpg`}
                        ></ContentCard>
                      ))}
                  </div>
                )}
              </div>
            ))}

          {selectedList && (
            <Modal
              onClose={() => {
                setSelectedList(null)
              }}
            >
              <WisdomListPage
                title={selectedList.title}
                list={selectedList.list}
                listTitleProp={selectedList.listTitleProp}
                listTextProp={selectedList.listTextProp}
                listImageProp={selectedList.listImageProp}
                generatedImage={selectedList.generatedImage}
                isLocked={selectedList.isLocked}
                onClick={(selectedSession) => {
                  if (!isPremium) {
                    setShowLandingPage(true)
                  } else {
                    setSelectedSession(selectedSession)
                  }
                }}
              ></WisdomListPage>
            </Modal>
          )}

          {selectedSession && (
            <Modal
              onClose={() => {
                setSelectedSession(null)
              }}
            >
              <CoachingDetail
                selectedSession={selectedSession}
                handleClose={() => {
                  setSelectedSession(null)
                }}
              ></CoachingDetail>
            </Modal>
          )}

          {selectedTrainingPlan && (
            <Modal
              onClose={() => {
                setSelectedTrainingPlan(null)
              }}
            >
              <TrainingPlanDetail
                selectedTrainingPlan={selectedTrainingPlan}
                handleClose={() => {
                  setSelectedTrainingPlan(null)
                }}
                onPlanStarted={() => {
                  setShowPlanStartedPage(true)
                  setSelectedTrainingPlan(null)
                }}
              ></TrainingPlanDetail>
            </Modal>
          )}

          {showLandingPage && (
            <Modal
              onClose={() => {
                setShowLandingPage(false)
              }}
            >
              <OnboardingPage onClose={() => setShowLandingPage(false)}></OnboardingPage>
            </Modal>
          )}

          {showAllTrainingPlans && (
            <Modal
              onClose={() => {
                setShowAllTrainingPlans(false)
              }}
            >
              <TrainingPlanList
                onClose={() => setShowAllTrainingPlans(false)}
                onPlanStarted={() => {
                  setShowAllTrainingPlans(false)
                  setShowPlanStartedPage(true)
                }}
                mappedCoachingSessions={mappedCoachingSessions}
              ></TrainingPlanList>
            </Modal>
          )}

          {showPlanStartedPage && (
            <Modal
              onClose={() => {
                setShowPlanStartedPage(false)
              }}
            >
              <TrainingPlanStarted
                onClose={() => {
                  setShowPlanStartedPage(false)
                }}
              ></TrainingPlanStarted>
            </Modal>
          )}
        </>
      )}
    </StyledCoachingSessions>
  )
}
