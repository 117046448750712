import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'context/APIcontext';
import * as animationData from 'assets/lottie/animation_onboarding_splash.json';
import Lottie from 'react-lottie';
import BlockButton from 'components/buttons/BlockButton';
import PageTitle from 'components/PageTitle';
import CheckmarkIcon from 'components/SVG/CheckmarkIcon';
import CloseIcon from 'components/SVG/CloseIcon';
import BottomSpacer from 'components/BottomSpacer';

const StyledPersonalizeAffirmationsPage = styled.div`
  max-width: 580px;
  margin: 0 auto;
  min-height: 90vh;
  padding: 10% 0;
`;

export default function PersonalizeAffirmationsPage({ handleClose }) {
  const { t } = useTranslation('common');

  const { savePersonalizedAffirmationCategories, getPersonalizedAffirmationCategories } = useAPI();

  const [personalizedAffirmationCategorys, setPersonalizedAffirmationCategorys] = useState();
  const [canSave, setCanSave] = useState(true);

  useEffect(() => {
    getPersonalizedAffirmationCategories().then((data) => {
      setPersonalizedAffirmationCategorys(data);
    });
  }, []);

  useEffect(() => {
    if (personalizedAffirmationCategorys) {
      const selectedAffirmations = personalizedAffirmationCategorys.filter((element) => {
        return element.isSelected;
      });

      if (selectedAffirmations.length === 0) {
        setCanSave(false);
      } else {
        setCanSave(true);
      }
    }
  }, [personalizedAffirmationCategorys]);

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  function setSelectedAffirmationCategorys(selectedCategory) {
    const newPersonalizedAffirmationCategorys = personalizedAffirmationCategorys.map((category) => {
      if (category.category === selectedCategory.category) {
        return {
          ...category,
          isSelected: !category.isSelected,
        };
      }
      return category;
    });

    setPersonalizedAffirmationCategorys(newPersonalizedAffirmationCategorys);
  }

  return (
    <StyledPersonalizeAffirmationsPage>
      <div className="animated fadeIn">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={defaultOptions.isStopped}
          isPaused={defaultOptions.isPaused}
        />
      </div>

      <PageTitle
        title={t('settings-page.personalize-affirmations')}
        text={t('settings-page.personalize-affirmations-text')}
      ></PageTitle>

      <div className="tag-cloud animated fadeIn">
        {personalizedAffirmationCategorys &&
          personalizedAffirmationCategorys.map((category, index) => (
            <div
              key={category + index}
              onClick={() => setSelectedAffirmationCategorys(category)}
              className={`tag ${category.isSelected ? 'is-selected' : 'is-red'}`}
            >
              {category.isSelected && <CheckmarkIcon />}
              {!category.isSelected && <CloseIcon />}
              {t(`affirmation.${category.category}`)}
            </div>
          ))}
      </div>

      <BottomSpacer />

      <BlockButton
        isBottom
        disabled={!canSave}
        text={t('save')}
        onClick={() => {
          savePersonalizedAffirmationCategories(personalizedAffirmationCategorys);
          handleClose();
        }}
      ></BlockButton>
    </StyledPersonalizeAffirmationsPage>
  );
}
