import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAPI } from 'context/APIcontext';
import PageTitle from 'components/PageTitle';
import SectionHeader from 'components/SectionHeader';
import BlockButton from 'components/buttons/BlockButton';

const StyledProfilePage = styled.div``;

export default function ProfilePage() {
  const { t } = useTranslation('common');
  const { getTarotSpreads, isPremium, deleteTasks } = useAPI();

  return (
    <StyledProfilePage>
      <PageTitle
        title={t('profile-page.headline')}
        text={t('profile-page.subheadline')}
      ></PageTitle>

      <SectionHeader
        title={t('profile-page.edit-profil-headline')}
        text={t('profile-page.edit-profil-text')}
      ></SectionHeader>

      <BlockButton
        isOutlined
        onClick={() => deleteTasks()}
        text={t('profile-page.cta-delete-tasks')}
      ></BlockButton>

      <BlockButton
        isOutlined
        onClick={() => deleteProfile()}
        text={t('profile-page.cta-delete-profile')}
      ></BlockButton>
    </StyledProfilePage>
  );
}
