import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PageTitle from 'components/PageTitle';
import { API_URL, ASSETS_URL, MOON_EXPLANATIONS_API } from 'constants/URLS';
import { useAPI } from 'context/APIcontext';
import LockClosedIcon from 'components/SVG/LockClosedIcon';
import SectionHeader from 'components/SectionHeader';
import TagCloud from 'components/TagCloud';
import { getLanguage } from 'helper/getLanguage';
import LandingPage from 'pages/LandingPage/LandingPage';

export default function MoonCalendarDetail({ currentMoonDetails, currentMoonExplanation }) {
  const [moonChangeTime, setMoonChangeTime] = useState(null);
  const [moonDoItems, setMoonDoItems] = useState([]);
  const [selectedMoonDoItem, setSelectedMoonDoItem] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(currentMoonDetails) && !_.isEmpty(currentMoonExplanation)) {
      console.log('currentMoonDetails', currentMoonDetails, currentMoonExplanation);
      prepareMoonData(currentMoonDetails, currentMoonExplanation);
    }
  }, []);

  function prepareMoonData(responseMoonData, responseMoonExplanation) {
    if (responseMoonData.moonChangeTimeUTC !== null) {
      setMoonChangeTime(
        dayjs.utc(responseMoonData.moonChangeTimeUTC).tz(dayjs.tz.guess()).format('HH:mm'),
      );
    } else {
      setMoonChangeTime(null);
    }

    let splittedDoItems = responseMoonExplanation[0].do
      .map((item) => {
        const matches = item.match(/<b>(.*?)<\/b>([^<]*)/);
        if (matches) {
          return {
            headline: matches[1].replace(':', '').trim(),
            text: matches[2].trim(),
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    if (splittedDoItems && Array.isArray(splittedDoItems)) {
      // Loop through the indices and assign topics if they exist
      for (let i = 0; i < splittedDoItems.length; i++) {
        if (splittedDoItems[i] && splittedDoItems[i].topic === undefined) {
          switch (i) {
            case 0:
              splittedDoItems[i].topic = encodeURIComponent('Body care');
              break;
            case 1:
              splittedDoItems[i].topic = encodeURIComponent('Plants');
              break;
            case 2:
              splittedDoItems[i].topic = encodeURIComponent('Health');
              break;
            case 3:
              splittedDoItems[i].topic = encodeURIComponent('Career');
              break;
            case 4:
              splittedDoItems[i].topic = encodeURIComponent('Travel');
              break;
            case 5:
              splittedDoItems[i].topic = encodeURIComponent('Spirituality');
              break;
            case 6:
              splittedDoItems[i].topic = encodeURIComponent('Big decisions');
              break;
            case 7:
              splittedDoItems[i].topic = encodeURIComponent('Social activities');
              break;
            case 8:
              splittedDoItems[i].topic = encodeURIComponent('Nutrition');
              break;
            default:
              break;
          }
        }
      }
    }

    setMoonDoItems(splittedDoItems);
    setSelectedMoonDoItem(splittedDoItems[0]);
  }

  return <div>MoonCalendarDetail</div>;
}
