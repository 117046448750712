import React, { useState } from 'react';
import styled from 'styled-components';
import { useAPI } from 'context/APIcontext';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import PageTitle from 'components/PageTitle';
import Lottie from 'react-lottie';
import * as animationData from 'assets/lottie/animation_onboarding_benefits.json';
import StarIcon from 'components/SVG/StarIcon';
import StarHalfIcon from 'components/SVG/StarHalfIcon';
import InformationCircleOutlineIcon from 'components/SVG/InformationCircleOutlineIcon';
import CheckmarkCircleIcon from 'components/SVG/CheckmarkCircleIcon';
import { LANDING_PAGE_PURCHASE } from 'constants/URLS';

const StyledLandingPage = styled.div`
  max-width: 580px;
  margin: 0 auto;
  min-height: 90vh;

  .premium-page-stars {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    margin: 0 3% 20px;

    svg {
      color: #111;
      width: 16px;
      height: 16px;
    }

    span {
      color: #111;
      padding-left: 8px;
    }
  }

  .old-price {
    display: inline-block;
    font-size: 18px;
    position: relative;
    font-weight: 600;
    text-align: center;

    &:before {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: red;

      bottom: 10px;
      left: 0;
      transform: rotate(-10deg);
    }
  }

  .premium-page-purchase {
    position: relative;
    background-color: #111;
    color: #fff;
    border-radius: 10px;
    padding: 12px 20px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    margin: 10px 3% 5px;
    box-shadow:
      0 2px 6px rgba(0, 0, 0, 0.08),
      0 1px 2px rgba(0, 0, 0, 0.12);
    font-weight: 600;
    line-height: 1.4;

    .left-side {
      /*display: flex;
    text-align: left;
    flex-direction: column;*/
      width: 100%;
    }

    .right-side {
      display: flex;
      text-align: right;
      flex-direction: column;
    }

    &.is-big-text {
      font-size: 15px;
      padding: 12px;
    }

    &.is-selected {
      background-color: #fff;
      color: #111;
      border: 1px solid #26d98c;
    }

    &.is-white {
      background-color: #f8f8f8;
      color: #111;
      border: 1px solid #f8f8f8;
    }

    .line-through {
      text-decoration: line-through;
    }

    .big-text {
      font-weight: 700;
      display: block;
      font-size: 17px;
      margin-bottom: 2px;

      .red {
        color: red;
        text-decoration: line-through;
      }
    }

    .small-text {
      font-weight: 400;
      display: block;
      font-size: 12px;
    }

    .left {
      text-align: left;
    }

    .right {
      text-align: left;
    }

    small {
      font-weight: 400;
      display: block;
      font-size: 13px;
    }

    /*span {
      font-weight: 400;
      display: block;
      font-size: 14px;
    }*/

    .premium-page-purchase-hint {
      position: absolute;
      top: -10px;
      right: 10px;
      padding: 2px 12px;
      background-color: red;
      color: #fff;
      font-size: 11px;
      border-radius: 8px;
      margin-left: -78px;
    }
  }

  .features-table {
    margin: 20px 0 0;

    .features-table-row {
      display: flex;
      justify-content: space-between;
      padding: 10px 0 10px 3%;

      &:nth-child(even) {
        background: #fff;
      }

      svg {
        fill: #111;
      }

      .features-table-col1 {
        width: 60%;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-align: left;

        .feature-icon-info {
          display: none;
          justify-content: start;
        }
      }

      .features-table-col2 {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .features-table-col3 {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .feature-icon-info {
        font-size: 24px;
        min-width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export default function LandingPage({ goBack }) {
  const { t } = useTranslation('common');

  const { userData } = useAPI();

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  return (
    <StyledLandingPage>
      <div className="animated fadeIn">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={defaultOptions.isStopped}
          isPaused={defaultOptions.isPaused}
        />
      </div>
      <PageTitle
        title={t('landing-page.page-title')}
        text={t('landing-page.page-text')}
      ></PageTitle>

      <div className="premium-page-stars">
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarHalfIcon />
        <span>{t('landing-page.ratings')}</span>
      </div>

      <div style={{ textAlign: 'center' }}>
        <div className="old-price">Statt 29,99€</div>
      </div>

      <div
        className="premium-page-purchase"
        onClick={() => {
          window.location.href = `${LANDING_PAGE_PURCHASE}`;
        }}
      >
        Einmalig 19,99€
        <div className="small-text">Kein Risiko & Keine Verpflichtungen</div>
        {/*<div className="small-text"> Kein Abo & Keine monatlichen Kosten</div>*/}
        {/*<div className="premium-page-purchase-hint">Nur noch 3 Tage</div>*/}
        <div className="premium-page-purchase-hint">Nur für kurze Zeit</div>
      </div>

      <div className="features-table">
        <div className="features-table-row">
          <div className="features-table-col1"></div>
          <div className="features-table-col2">Kostenlos</div>
          <div className="features-table-col3">Pro</div>
        </div>
        <div className="features-table-row">
          <div className="features-table-col1">
            <div className="feature-icon-info">
              <InformationCircleOutlineIcon />
            </div>
            Kostenlose Rezepte
          </div>
          <div className="features-table-col2">
            <div className="feature-icon-info">
              <CheckmarkCircleIcon />
            </div>
          </div>
          <div className="features-table-col3">
            <div className="feature-icon-info">
              <CheckmarkCircleIcon />
            </div>
          </div>
        </div>
        <div className="features-table-row">
          <div className="features-table-col1">
            <div className="feature-icon-info">
              <InformationCircleOutlineIcon />
            </div>
            Persönlicher Ernährungsplan
          </div>
          <div className="features-table-col2">
            <div className="feature-icon-info">
              <ion-icon name="lock-closed-outline"></ion-icon>
            </div>
          </div>
          <div className="features-table-col3">
            <div className="feature-icon-info">
              <CheckmarkCircleIcon />
            </div>
          </div>
        </div>
        <div className="features-table-row">
          <div className="features-table-col1">
            <div className="feature-icon-info">
              <InformationCircleOutlineIcon />
            </div>
            Trainingsplan für Zuhause
          </div>
          <div className="features-table-col2">
            <div className="feature-icon-info">
              <ion-icon name="lock-closed-outline"></ion-icon>
            </div>
          </div>
          <div className="features-table-col3">
            <div className="feature-icon-info">
              <CheckmarkCircleIcon />
            </div>
          </div>
        </div>
        <div className="features-table-row">
          <div className="features-table-col1">
            <div className="feature-icon-info">
              <InformationCircleOutlineIcon />
            </div>
            Tägliche Hör-Lektionen
          </div>
          <div className="features-table-col2">
            <div className="feature-icon-info">
              <ion-icon name="lock-closed-outline"></ion-icon>
            </div>
          </div>
          <div className="features-table-col3">
            <div className="feature-icon-info">
              <CheckmarkCircleIcon />
            </div>
          </div>
        </div>
        <div className="features-table-row">
          <div className="features-table-col1">
            <div className="feature-icon-info">
              <InformationCircleOutlineIcon />
            </div>
            Intelligente Einkaufsliste
          </div>
          <div className="features-table-col2">
            <div className="feature-icon-info">
              <ion-icon name="lock-closed-outline"></ion-icon>
            </div>
          </div>
          <div className="features-table-col3">
            <div className="feature-icon-info">
              <CheckmarkCircleIcon />
            </div>
          </div>
        </div>
        <div className="features-table-row">
          <div className="features-table-col1">
            <div className="feature-icon-info">
              <InformationCircleOutlineIcon />
            </div>
            Tägliche Aufgaben & Tipps
          </div>
          <div className="features-table-col2">
            <div className="feature-icon-info">
              <ion-icon name="lock-closed-outline"></ion-icon>
            </div>
          </div>
          <div className="features-table-col3">
            <div className="feature-icon-info">
              <CheckmarkCircleIcon />
            </div>
          </div>
        </div>
        <div className="features-table-row">
          <div className="features-table-col1">
            <div className="feature-icon-info">
              <InformationCircleOutlineIcon />
            </div>
            Ohne Hungern & Jojo Effekt
          </div>
          <div className="features-table-col2">
            <div className="feature-icon-info">
              <ion-icon name="lock-closed-outline"></ion-icon>
            </div>
          </div>
          <div className="features-table-col3">
            <div className="feature-icon-info">
              <CheckmarkCircleIcon />
            </div>
          </div>
        </div>
      </div>
    </StyledLandingPage>
  );
}
