function calculateSelfCareScore(moonData) {
  let score = 'semi';

  if (moonData.moonPhase === 'wanin_crescent') {
    score = 'good';
  }

  if (moonData.moonSign === 'sagittarius') {
    score = 'good';
  }

  if (parseInt(moonData.position.split('°')[0]) < 10) {
    score = 'good';
  }

  if (moonData.zodiac === 'leo') {
    score = 'good';
  }

  return score;
}

function calculatePlantingScore(moonData) {
  let score = 'semi';

  if (moonData.moonPhase === 'new_moon' || moonData.moonPhase === 'waxing_crescent') {
    score = 'good';
  } else if (moonData.moonPhase === 'full_moon' || moonData.moonPhase === 'waning_crescent') {
    score = 'bad';
  }

  /*
  const fertileSigns = ['taurus', 'cancer', 'libra', 'scorpio'];
  if (fertileSigns.includes(moonData.moonSign)) {
    score = score === 'good' ? 'good' : 'semi';
  } else {
    const infertileSigns = ['aries', 'gemini', 'leo', 'virgo'];
    if (infertileSigns.includes(moonData.moonSign)) {
      score = 'bad';
    }
  }*/

  // Influence of position - assumed simplified here
  // (In practice, the position could be considered in detail to take specific aspects into account.)

  // Influence of user's zodiac sign - not considered in this example
  // (The user's zodiac sign usually does not have a direct influence on planting.)

  return score;
}

function calculateTravellingScore(moonData) {
  let score = 'semi';

  if (moonData.moonPhase === 'waxing_crescent' || moonData.moonPhase === 'first_quarter') {
    score = 'good';
  } else if (moonData.moonPhase === 'waning_gibbous' || moonData.moonPhase === 'last_quarter') {
    score = 'bad';
  }

  if (moonData.moonSign === 'sagittarius' || moonData.moonSign === 'gemini') {
    score = 'good';
  } else if (moonData.moonSign === 'cancer' || moonData.moonSign === 'capricorn') {
    score = 'bad';
  }

  if (moonData.zodiac === 'sagittarius' || moonData.zodiac === 'aries') {
    score = 'good';
  }

  return score;
}

function calculateNutritionScore(moonData) {
  let score = 'semi';

  if (moonData.moonPhase === 'new_moon' || moonData.moonPhase === 'waxing_crescent') {
    score = 'good';
  } else if (moonData.moonPhase === 'full_moon' || moonData.moonPhase === 'waning_crescent') {
    score = 'bad';
  }

  if (moonData.moonSign === 'cancer' || moonData.moonSign === 'taurus') {
    score = 'good';
  } else if (moonData.moonSign === 'aries' || moonData.moonSign === 'gemini') {
    score = 'semi';
  }

  return score;
}

function calculateCareerScore(moonData) {
  let score = 'semi';

  if (moonData.moonPhase === 'new_moon' || moonData.moonPhase === 'waxing_crescent') {
    score = 'good';
  } else if (moonData.moonPhase === 'full_moon' || moonData.moonPhase === 'waning_gibbous') {
    score = 'bad';
  }

  if (moonData.moonSign === 'capricorn' || moonData.moonSign === 'taurus') {
    score = 'good';
  } else if (moonData.moonSign === 'aries' || moonData.moonSign === 'cancer') {
    score = 'semi';
  }

  if (moonData.zodiac === 'leo' || moonData.zodiac === 'aquarius') {
    score = 'good';
  }

  return score;
}

function calculateSpiritualityScore(moonData) {
  let score = 'semi';

  if (moonData.moonPhase === 'full_moon' || moonData.moonPhase === 'waning_gibbous') {
    score = 'good';
  } else if (moonData.moonPhase === 'new_moon' || moonData.moonPhase === 'waxing_crescent') {
    score = 'semi';
  }

  if (moonData.moonSign === 'pisces' || moonData.moonSign === 'cancer') {
    score = 'good';
  } else if (moonData.moonSign === 'aries' || moonData.moonSign === 'gemini') {
    score = 'semi';
  }

  if (moonData.zodiac === 'sagittarius' || moonData.zodiac === 'aquarius') {
    score = 'good';
  }

  return score;
}

function calculateHealthScore(moonData) {
  let score = 'semi';

  if (moonData.moonPhase === 'waning_crescent' || moonData.moonPhase === 'last_quarter') {
    score = 'good';
  } else if (moonData.moonPhase === 'new_moon' || moonData.moonPhase === 'first_quarter') {
    score = 'semi';
  }

  if (moonData.moonSign === 'virgo' || moonData.moonSign === 'taurus') {
    score = 'good';
  } else if (moonData.moonSign === 'sagittarius' || moonData.moonSign === 'gemini') {
    score = 'semi';
  }

  if (moonData.zodiac === 'capricorn' || moonData.zodiac === 'scorpio') {
    score = 'good';
  }

  return score;
}

function calculateBeautyScore(moonData) {
  let score = 'semi';

  if (moonData.moonPhase === 'waxing_crescent' || moonData.moonPhase === 'first_quarter') {
    score = 'good';
  } else if (moonData.moonPhase === 'waning_gibbous' || moonData.moonPhase === 'last_quarter') {
    score = 'bad';
  }

  if (moonData.moonSign === 'libra' || moonData.moonSign === 'taurus') {
    score = 'good';
  } else if (moonData.moonSign === 'aries' || moonData.moonSign === 'scorpio') {
    score = 'semi';
  }

  if (moonData.zodiac === 'virgo' || moonData.zodiac === 'pisces') {
    score = 'good';
  }

  return score;
}

function calculateCompletionScore(moonData) {
  let score = 'semi';

  if (moonData.moonPhase === 'waning_gibbous' || moonData.moonPhase === 'last_quarter') {
    score = 'good';
  } else if (moonData.moonPhase === 'new_moon' || moonData.moonPhase === 'first_quarter') {
    score = 'bad';
  }

  if (moonData.moonSign === 'capricorn' || moonData.moonSign === 'virgo') {
    score = 'good';
  } else if (moonData.moonSign === 'aries' || moonData.moonSign === 'libra') {
    score = 'semi';
  }

  if (moonData.zodiac === 'scorpio' || moonData.zodiac === 'taurus') {
    score = 'good';
  }

  return score;
}

function calculateRomanceScore(moonData) {
  let score = 'semi';

  if (moonData.moonPhase === 'full_moon' || moonData.moonPhase === 'waxing_gibbous') {
    score = 'good';
  } else if (moonData.moonPhase === 'new_moon' || moonData.moonPhase === 'waning_crescent') {
    score = 'semi';
  }

  if (moonData.moonSign === 'libra' || moonData.moonSign === 'pisces') {
    score = 'good';
  } else if (moonData.moonSign === 'aries' || moonData.moonSign === 'virgo') {
    score = 'semi';
  }

  if (moonData.zodiac === 'leo' || moonData.zodiac === 'aquarius') {
    score = 'good';
  }

  return score;
}

function calculateBigDecisionScore(moonData) {
  let score = 'semi';

  if (moonData.moonPhase === 'full_moon' || moonData.moonPhase === 'waxing_gibbous') {
    score = 'bad';
  } else if (moonData.moonPhase === 'new_moon' || moonData.moonPhase === 'waxing_crescent') {
    score = 'good';
  }

  if (moonData.moonSign === 'capricorn' || moonData.moonSign === 'taurus') {
    score = 'good';
  } else if (moonData.moonSign === 'gemini' || moonData.moonSign === 'pisces') {
    score = 'semi';
  }

  if (moonData.zodiac === 'scorpio' || moonData.zodiac === 'leo') {
    score = 'good';
  }

  return score;
}

function calculateSocialActivityScore(moonData) {
  let score = 'semi';

  if (moonData.moonPhase === 'waxing_gibbous' || moonData.moonPhase === 'full_moon') {
    score = 'good';
  } else if (moonData.moonPhase === 'waning_crescent' || moonData.moonPhase === 'new_moon') {
    score = 'bad';
  }

  if (moonData.moonSign === 'gemini' || moonData.moonSign === 'aquarius') {
    score = 'good';
  } else if (moonData.moonSign === 'cancer' || moonData.moonSign === 'capricorn') {
    score = 'semi';
  }

  if (moonData.zodiac === 'leo' || moonData.zodiac === 'sagittarius') {
    score = 'good';
  }

  return score;
}

// Implement other score calculation functions similarly...

function calculateAspectScore(aspect, moonData) {
  switch (aspect) {
    case 'nutrition':
      return calculateNutritionScore(moonData);
    case 'planting':
      return calculatePlantingScore(moonData);
    case 'selfCare':
      return calculateSelfCareScore(moonData);
    case 'travelling':
      return calculateTravellingScore(moonData);
    case 'career':
      return calculateCareerScore(moonData);
    case 'spirituality':
      return calculateSpiritualityScore(moonData);
    case 'health':
      return calculateHealthScore(moonData);
    case 'beauty':
      return calculateBeautyScore(moonData);
    case 'completion':
      return calculateCompletionScore(moonData);
    case 'romance':
      return calculateRomanceScore(moonData);
    case 'bigDecision':
      return calculateBigDecisionScore(moonData);
    case 'socialActivity':
      return calculateSocialActivityScore(moonData);
    default:
      return 'semi';
  }
}

export function calculateScoring(moonData) {
  const scoring = {};

  const aspects = [
    'nutrition',
    'planting',
    'selfCare',
    'travelling',
    'career',
    'spirituality',
    'health',
    'beauty',
    'completion',
    'romance',
    'bigDecision',
    'socialActivity',
  ];
  for (const aspect of aspects) {
    scoring[aspect] = calculateAspectScore(aspect, moonData);
  }

  return scoring;
}

// Implement other score calculation functions similarly...

const moonData = {
  date: 'January 8, 2024',
  moonPhase: 'waning_crescent',
  moonSign: 'sagittarius',
  position: '8°17’',
  zodiac: 'leo',
};

const scorings = calculateScoring(moonData);
