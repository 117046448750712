import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BlockButton from 'components/buttons/BlockButton';

const StyledTextParser = styled.div`
  display: inline;

  .protip {
    color: #c19c7b;
    font-weight: 700;
  }
`;

// Helper function to parse text
const parseText = (text) => {
  const parts = [];
  const regex = /{{(.*?)}}/g;
  let lastIndex = 0;
  let match;

  while ((match = regex.exec(text)) !== null) {
    // Push the text before the match
    if (match.index > lastIndex) {
      parts.push({ type: 'text', content: text.substring(lastIndex, match.index) });
    }

    // Extract the matched content and type if present
    const contentParts = match[1].split(/##|>>/).map((part) => part.trim());
    const content = contentParts[0];
    const type = contentParts[1] || 'div'; // Default type is 'div'

    parts.push({ type, content });

    lastIndex = regex.lastIndex;
  }

  // Push remaining text after the last match
  if (lastIndex < text.length) {
    parts.push({ type: 'text', content: text.substring(lastIndex) });
  }

  return parts;
};

// TextParser Component
const TextParser = ({ text }) => {
  const parsedContent = parseText(text);

  return (
    <StyledTextParser>
      {parsedContent.map((part, index) => {
        switch (part.type) {
          case 'text':
            return part.content.length > 5 ? (
              <div key={index} style={{ whiteSpace: 'pre-line' }}>
                {part.content}
              </div>
            ) : (
              <></>
            );
          case 'image':
            return <></>; // Placeholder for image rendering
          case 'add_to_playbook':
            return (
              <BlockButton
                key={index}
                style={{ margin: '0' }}
                isOutlined
                onClick={() => console.log('')}
                text={part.content}
              />
            );
          case 'protip':
            return (
              <span key={index} className="protip">
                {part.content}
              </span>
            );
          default:
            return (
              <span key={index} className={part.type}>
                {part.content}
              </span>
            );
        }
      })}
    </StyledTextParser>
  );
};

TextParser.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextParser;
