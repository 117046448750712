import { AFFIRMATIONS_API, BASE_URL } from 'constants/URLS'
import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import screenshotShare from 'helper/screenshotShare'
import { getLanguage } from 'helper/getLanguage'
import styled from 'styled-components'
import { useAPI } from 'context/APIcontext'
import BookIcon from 'components/SVG/BookIcon'
import BulbIcon from 'components/SVG/BulbIcon'
import ShareIcon from 'components/SVG/ShareIcon'
import Modal from 'components/Modal'
import StoryPage from 'pages/StoryPage/StoryPage'
import LoadingState from 'components/LoadingState'
import PersonalizeAffirmationsPage from 'pages/PersonalizeAffirmationsPage/PersonalizeAffirmationsPage'
import BottomSpacer from 'components/BottomSpacer'
import { useShareImageContext } from 'context/ShareContext'
import OnboardingPage from 'pages/OnboardingPage/OnboardingPage'
import BlockButton from 'components/buttons/BlockButton'
import LockClosedIcon from 'components/SVG/LockClosedIcon'

const StyledAffirmationPage = styled.div`
  max-width: 460px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: var(--ion-safe-area-bottom, 0);
  position: relative;
  height: 100vh;

  .affirmation-category {
    text-transform: uppercase;
    margin-top: var(--ion-safe-area-top, 0);
    position: absolute;
    top: 14px;
    width: 100%;
    text-align: center;
  }

  .affirmation-text {
    color: #000;
    font-family: 'now', sans-serif;
    font-size: 30px;
    text-align: center;
    line-height: 1.4;
    max-width: 100%;
    position: absolute;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
    top: 50%;
    padding: 0 3%;

    b {
      color: #c19c7b;
      font-family: 'breathing', sans-serif;
      font-size: 36px;
      line-height: 1.2;
      position: relative;
      top: 10px;
      left: 4px;
    }
  }
`

export default function AffirmationPage({ handleClose }) {
  const { t } = useTranslation('common')
  const { userData, saveAffirmation, isPremium } = useAPI()
  const { openModal } = useShareImageContext()

  const [dailyAffirmation, setDailyAffirmation] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showMore, setShowMore] = useState(false)
  const [showLandingPage, setShowLandingPage] = useState(false)

  const [personalizeAffirmationCategoriesModalOpen, setPersonalizeAffirmationCategoriesModalOpen] =
    useState(false)

  useEffect(() => {
    loadDailyAffirmation()
  }, [])

  const loadDailyAffirmation = async () => {
    const personalizedAffirmationCategorys =
      (await userData.PERSONALIZED_AFFIRMATION_CATEGORYS) || []

    const storedAffirmation = await getTodaysAffirmation()

    if (storedAffirmation) {
      storedAffirmation.shorttext = storedAffirmation.shorttext
      setDailyAffirmation(storedAffirmation)
      setIsLoading(false)
    } else {
      try {
        const allAffirmations = await getAllAffirmations()
        selectAndStoreAffirmation(allAffirmations, personalizedAffirmationCategorys)
        setIsLoading(false)
      } catch (error) {
        console.error('Failed to load affirmations', error)
      }
    }
  }

  const takeAndShareScreenshot = async () => {
    const imageData = await screenshotShare()
    openModal(imageData)
  }

  const wrapWordsAndAddClass = (text) => {
    // Zuerst behandeln wir spezifisch die <b>-Tags, indem wir die Klasse zum Tag hinzufügen, aber nicht zum Inhalt
    text = text.replace(/<b>(.*?)<\/b>/g, '<b className="animation animated fadeIn">$1</b>')

    // Nun wickeln wir Sequenzen außerhalb von HTML-Tags mit dem span-Tag ein, wobei Leerzeichen als Trenner verwendet werden,
    // und schließen Text innerhalb von <b>-Tags aus.
    // Angepasster Regex, um Unicode-Zeichen und Kombinationszeichen zu berücksichtigen, sowie die Behandlung von Leerzeichen als Trenner.
    const regex = /(<[^>]+>)|([^<>\s]+)/gu
    return text.replace(regex, (match, p1, p2) => {
      if (p1) {
        // Wenn Teil eines HTML-Tags, unverändert zurückgeben, es sei denn, es ist außerhalb eines <b>-Tags, wo wir die Klasse hinzufügen
        return p1.includes('<b') || p1.includes('</b>')
          ? p1
          : `<span className="animation animated fadeIn">${p1}</span>`
      } else {
        // Wenn es sich um eine Sequenz handelt, die durch Leerzeichen getrennt ist, wickeln wir sie mit dem span-Tag ein
        return `<span className="animation animated fadeIn">${p2}</span>`
      }
    })
  }

  const getTodaysAffirmation = async () => {
    const today = dayjs().format('YYYY-MM-DD')
    const storedAffirmation = userData.affirmation

    if (storedAffirmation) {
      if (storedAffirmation.Date === today) {
        return storedAffirmation
      }
    }
    return null
  }

  const selectAndStoreAffirmation = (allAffirmations, personalizedAffirmationCategorys) => {
    const newAffirmation = selectRandomAffirmation(
      allAffirmations,
      personalizedAffirmationCategorys,
    )
    newAffirmation.shorttext = wrapWordsAndAddClass(newAffirmation.shorttext)
    saveAffirmation(newAffirmation)
    setDailyAffirmation(newAffirmation)
  }

  const selectRandomAffirmation = (data, personalizedAffirmationCategorys) => {
    const filteredAffirmations = data.filter((affirmation) => {
      return personalizedAffirmationCategorys.some(
        (category) => category.category === affirmation.category && category.isSelected,
      )
    })

    let selectedAffirmation = data[Math.floor(Math.random() * data.length)]
    if (filteredAffirmations.length > 0) {
      selectedAffirmation =
        filteredAffirmations[Math.floor(Math.random() * filteredAffirmations.length)]
    }

    return {
      title: selectedAffirmation.title,
      content: selectedAffirmation.content,
      topic: selectedAffirmation.topic,
      category: selectedAffirmation.category,
      shorttext: selectedAffirmation.shorttext,
      Date: dayjs().format('YYYY-MM-DD'),
    }
  }

  const getAllAffirmations = async () => {
    const affirmationURL = `${AFFIRMATIONS_API}?language=${getLanguage()}`
    try {
      const response = await fetch(affirmationURL)
      const data = await response.json()
      return data
    } catch (error) {
      console.error('Failed to load affirmations', error)
      return []
    }
  }

  return (
    <StyledAffirmationPage data-id="screenshot-area">
      {isLoading ? (
        <LoadingState
          title={t('affirmation.loading-headline')}
          text={t('affirmation.loading-text')}
        />
      ) : (
        <>
          {dailyAffirmation && (
            <>
              <div className="affirmation-category">
                {t('affirmation.' + dailyAffirmation.category)}
              </div>

              <div>&nbsp;</div>

              <div className="affirmation-text">
                <div dangerouslySetInnerHTML={{ __html: dailyAffirmation.shorttext }}></div>
                <div className="hide-to-share">
                  <BlockButton
                    noBorder
                    isOutlined
                    onClick={() => setShowMore(true)}
                    text={t('tell-me-more')}
                  ></BlockButton>
                </div>
              </div>

              <div className="story-button-wrapper hide-to-share">
                <div className="story-button-share" onClick={takeAndShareScreenshot}>
                  <ShareIcon />
                  <span>{t('share')}</span>
                </div>
                <div
                  className="story-button-share"
                  onClick={() => {
                    if (isPremium) {
                      setPersonalizeAffirmationCategoriesModalOpen(true)
                    } else {
                      setShowLandingPage(true)
                    }
                  }}
                >
                  {isPremium ? <BulbIcon /> : <LockClosedIcon />}
                  <span>{t('personalize')}</span>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <BottomSpacer />

      <div className="screenshot-watermark show-to-share">
        <img
          className="screenshot-watermark-logo"
          src={`${BASE_URL}/react/assets/img/tiny-affirmation-logo-share.png`}
        ></img>
        <div className="screenshot-watermark-text">
          <h4>{t('screenshot-watermark-headline')}</h4>
          <span>{t('screenshot-watermark-text')}</span>
        </div>
      </div>

      {personalizeAffirmationCategoriesModalOpen && (
        <Modal
          onClose={() => {
            setPersonalizeAffirmationCategoriesModalOpen(false)
          }}
        >
          <PersonalizeAffirmationsPage
            handleClose={() => {
              setPersonalizeAffirmationCategoriesModalOpen(false)
            }}
          ></PersonalizeAffirmationsPage>
        </Modal>
      )}

      {showMore && (
        <Modal
          onClose={() => {
            setShowMore(false)
          }}
        >
          <StoryPage
            title={dailyAffirmation.title}
            text={dailyAffirmation.content}
            handleClose={() => {
              setShowMore(false)
            }}
          ></StoryPage>
        </Modal>
      )}

      {showLandingPage && (
        <Modal
          onClose={() => {
            setShowLandingPage(false)
          }}
        >
          <OnboardingPage onClose={() => setShowLandingPage(false)}></OnboardingPage>
        </Modal>
      )}
    </StyledAffirmationPage>
  )
}
