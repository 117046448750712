export default function isImportantAspect(aspect) {
  // Definieren Sie, welche Aspekte und/oder Planeten als wichtig betrachtet werden
  const importantAspects = ['Conjunction', 'Trine', 'Opposition'];
  const importantPlanets = ['Sun', 'Moon', 'Mercury', 'Venus', 'Mars'];

  // Prüfen Sie, ob der Aspekttyp als wichtig betrachtet wird
  const isImportantType = importantAspects.includes(aspect.type);

  // Prüfen Sie, ob einer der beteiligten Planeten als wichtig betrachtet wird
  const isActivePlanetImportant = importantPlanets.includes(aspect.active.name);
  const isPassivePlanetImportant = importantPlanets.includes(aspect.passive.name);

  // Entscheiden Sie, ob der Aspekt basierend auf Ihren Kriterien wichtig ist
  return isImportantType && (isActivePlanetImportant || isPassivePlanetImportant);
}
