import React, { useState } from 'react';
import TaskCard from './TaskCard';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import BlockButton from './buttons/BlockButton';
import { BASE_URL } from 'constants/URLS';

const StyledFriendsCard = styled.div`
  background-color: #fff;
  //margin-left: 3%;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 20px;
  margin-right: 20px;
  box-shadow: #cccccc 0 0 16px;

  .friends-name {
    font-weight: 700;
    font-family: 'clever-german', serif;
    font-size: 26px;
    text-align: center;
    margin: 10px 0 6px;
  }

  .friends-birthday {
    font-family: 'clever-german', serif;
    font-size: 18px;
    text-align: center;
    margin-bottom: 2px;
  }

  .friends-zodiac {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }

  .friends-zodiac-image {
    width: 100px;
    height: 100px;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }
`;
function FriendsCard({ friend, goToHoroscope, goToBirthChartPage, removeFriend }) {
  const { t } = useTranslation('common');

  const zodiacBackground = {
    backgroundImage: friend.zodiacSign
      ? `url(${BASE_URL}/api/images/watercolor/zodiac-${encodeURIComponent(
          friend.zodiacSign.toLowerCase().replace(/ /g, '_'),
        )}.jpg)`
      : `url(${BASE_URL}/api/images/watercolor/person.jpg)`,
  };

  const [taskList] = useState([
    {
      title: t('daily-horoscope'),
      text: t('daily-horoscope-text'),
      onClick: () => goToHoroscope(),
      topic: '',
    },
    {
      title: t('birthchart'),
      text: t('birthchart-text'),
      onClick: () => goToBirthChartPage(),
      topic: '',
    },
  ]);

  return (
    <StyledFriendsCard>
      <div className="friends-zodiac-image" style={zodiacBackground}></div>
      <div className="friends-name">{friend.name}</div>
      {friend.birthDay && (
        <div className="friends-birthday">
          {friend.birthDay}.{friend.birthMonth}.{friend.birthYear}
        </div>
      )}
      {!friend.birthDay && (
        <div className="friends-birthday">
          {t('not-registered')}
          <BlockButton
            isOutlined={true}
            onClick={() => goToHoroscope()}
            text={t('login')}
          ></BlockButton>
        </div>
      )}
      <div className="friends-zodiac">{t(friend.zodiacSign)}</div>

      {taskList.map((task, index) => (
        <TaskCard
          key={'task' + index}
          title={task.title}
          text={task.text}
          topic={task.topic}
          onClick={() => {
            task.onClick();
          }}
          isDone={false}
        ></TaskCard>
      ))}

      {!friend.isMe && (
        <BlockButton
          isOutlined={true}
          noBorder={true}
          onClick={() => removeFriend(friend)}
          text={t('friends.remove-friend')}
        ></BlockButton>
      )}
    </StyledFriendsCard>
  );
}

export default FriendsCard;
