import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

const StyledReactSlickSlider = styled.div`
  max-width: ${(props) => (props.hasMaxWidth ? '720px' : 'none')};
  overflow: visible;
  margin: 0 3% 20px;

  @media (min-width: 720px) {
    margin: 0 auto 20px;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-dots {
    opacity: 0.4;
  }

  .slick-arrow {
    display: none !important;
  }
`;

export default function StyledSlider({ children, hasMaxWidth, onClick, ...props }) {
  const sliderRef = useRef(null);
  const [isSliding, setIsSliding] = useState(false);

  const handleChildClick = (index, childOnClick) => (event) => {
    if (isSliding) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      childOnClick && childOnClick(event);
      onClick && onClick(index);
    }
  };

  const customProps = {
    ...props,
    beforeChange: (current, next) => {
      setIsSliding(true);
      if (props.beforeChange) {
        props.beforeChange(current, next);
      }
    },
    afterChange: (current) => {
      setIsSliding(false);
      if (props.afterChange) {
        props.afterChange(current);
      }
    },
  };

  return (
    <StyledReactSlickSlider hasMaxWidth={hasMaxWidth}>
      <Slider ref={sliderRef} {...customProps}>
        {React.Children.map(children, (child, index) => {
          // Clone the child to attach the new click handler
          return React.cloneElement(child, {
            onClick: handleChildClick(index, child.props.onClick),
          });
        })}
      </Slider>
    </StyledReactSlickSlider>
  );
}
