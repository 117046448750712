import React from 'react';

import styled from 'styled-components';

const StyledSectionHeader = styled.div`
  position: relative;
  overflow: hidden;
  /*display: flex;
  align-items: center;
  justify-content: space-between;*/
  padding-top: 0;
  max-width: 720px;
  max-width: 720px;
  margin: 0 3% 20px;

  @media (min-width: 720px) {
    margin: 0 auto 20px;
  }

  .is-left {
    margin-top: 20px;
    margin-left: 0;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      padding: 0;
      text-align: left;
      margin: 0;
    }
  }

  .no-spacing {
    h4 {
      margin: 0;
      padding: 0;
    }
  }

  .is-small {
    h4 {
      font-size: 14px;
      font-family: 'Open Sans';
    }
  }

  @media (min-width: 720px) {
    margin: 20px auto 20px;
  }

  &.section-header--has-subline {
    margin-bottom: 0;
  }

  &:first-of-type {
    border: 0 none;
    margin-top: 0;
  }

  h4 {
    line-height: 1.4;
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    color: #111;
    text-align: center;
    font-family: 'clever-german', serif;
    margin: 20px 10% 20px;
    padding-top: 20px;

    b {
      font-weight: 600;
    }

    small {
      display: block;
      font-size: 15px;
      font-weight: 400;
      font-family: 'Open Sans', sans-serif;
      line-height: 1.4;
      color: #111;
    }
  }

  p {
    text-align: center;
    margin: 0;
    font-size: 14px;
  }

  .section-header--show-more {
    font-size: 12px;
    font-weight: 700;
    color: #c19c7b;
    border: 1px solid #c19c7b;
    padding: 4px 10px;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 1px;
    white-space: nowrap;

    span {
    }
  }
`;

export default function SectionHeader({
  title,
  text,
  isLeft,
  onShowMore,
  showMoreText,
  noSpacing = false,
  isSmall = false,
}) {
  function createMarkup() {
    return { __html: text };
  }

  return (
    <StyledSectionHeader>
      <div
        className={`section-header animated fadeIn ${isLeft ? 'is-left' : ''} ${
          noSpacing ? 'no-spacing' : ''
        } ${isSmall ? 'is-small' : ''}`}
      >
        <h4>
          <span>{title}</span>
          {text && <small dangerouslySetInnerHTML={createMarkup()}></small>}
        </h4>

        {onShowMore && (
          <div className="section-header--show-more" onClick={onShowMore}>
            {showMoreText}
          </div>
        )}
      </div>
    </StyledSectionHeader>
  );
}
