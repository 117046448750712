import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PageTitle from 'components/PageTitle';
import SectionHeader from 'components/SectionHeader'; // Assuming you have this component
import BlockButton from 'components/buttons/BlockButton';
import LoadingState from 'components/LoadingState';
import { TAROT_AI_READING_API, TAROT_EXPLANATIONS_API } from 'constants/URLS';
import { getLanguage } from 'helper/getLanguage';

const StyledAIReading = styled.div`
  margin-top: 60px;
  padding-bottom: 100px;

  textarea {
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 16px;
    padding: 10px;
    height: 50vh;
    line-height: 1.2;
    background-color: #fff;
    margin: 0 3% 20px;
    width: 94%;

    &:read-only {
      color: #ddd;
      background-color: whitesmoke;
    }

    &::placeholder {
      color: #ddd;
    }

    @media only screen and (max-width: 375px) {
      font-size: 14px;
    }
  }
`;

export default function AIReading({
  userQuestion,
  currentSpread,
  userQuestionAnswered,
  updateUserQuestion,
  onAIReading,
}) {
  const { t } = useTranslation('common');

  // State for the textarea input
  const [question, setQuestion] = useState(userQuestion);
  const [isLoading, setIsLoading] = useState(false);

  let cardsWithPosition = '';
  currentSpread.cards.forEach((card, cardPosition) => {
    cardsWithPosition += `${card.selectedCard.cardName} ${
      card.reversed ? 'reversed' : ''
    } in position ${cardPosition + 1} with the meaning ${card.name}, `;
  });

  // Handler for when the textarea value changes
  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
    updateUserQuestion(e.target.value);
  };

  const getTarotReading = async () => {
    setIsLoading(true);

    let cardsWithPosition = '';
    currentSpread.cards.forEach((card, cardPosition) => {
      cardsWithPosition += `${card.selectedCard.cardName} ${
        card.reversed ? 'reversed' : ''
      } in position ${cardPosition + 1} with the meaning ${card.name}, `;
    });

    let tarotReadingAPI = `${TAROT_AI_READING_API}?titles[]=${encodeURIComponent(
      cardsWithPosition,
    )}&spreadTitle=${encodeURIComponent(currentSpread.title)}&spreadQuestion=${encodeURIComponent(
      question,
    )}&language=${getLanguage()}`;

    try {
      const response = await fetch(tarotReadingAPI);
      const data = await response.json();
      onAIReading(data[0]);
    } catch (error) {
      return [];
    }
  };

  return (
    <StyledAIReading>
      {isLoading ? (
        <LoadingState title={t('spreads.loading-headline')} text={t('spreads.loading-text')} />
      ) : (
        <>
          <PageTitle
            title={t('spreads.ai-reading.title')}
            text={t('spreads.ai-reading.description')}
          />

          <div className="animated fadeIn">
            <SectionHeader
              title={t('spread.your-question-headline')}
              text={t('spread.your-question-text')}
              noSpacing
            />

            <textarea
              placeholder={t('spread.your-question-placeholder')}
              value={question}
              onChange={handleQuestionChange}
              readOnly={userQuestionAnswered}
            ></textarea>
          </div>

          <BlockButton
            isBottom
            className="animation animated fadeIn hide-to-share"
            text={t('continue')}
            onClick={() => {
              getTarotReading();
            }}
          ></BlockButton>
        </>
      )}
    </StyledAIReading>
  );
}
