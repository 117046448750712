import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { API_URL, ASSETS_URL, BASE_URL } from 'constants/URLS'
import { useTranslation } from 'react-i18next'
import { useAPI } from 'context/APIcontext'
import styled from 'styled-components'
import ContentCard from './ContentCard'
import SectionHeader from './SectionHeader'
import TagCloud from './TagCloud'
import Modal from './Modal'
import StoryPage from 'pages/StoryPage/StoryPage'

const StyledAstroEvents = styled.div``

export default function AstrologyEvents({ date, mode = 'day' }) {
  const { t } = useTranslation('common')
  const { getAstroEventExplaination } = useAPI()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const [selection, setSelection] = useState([
    {
      headline: t('astro-events.core-events'),
      icon: '📆',
    },
    {
      headline: t('astro-events.secondary-aspects'),
      icon: '📌',
    },
    {
      headline: t('astro-events.all'),
      icon: '🧷',
    },
  ])
  const [selectedView, setSelectedView] = useState(selection[0])
  const [concjunctions, setConcjunctions] = useState([])
  const [anyMajorAspect, setAnyMajorAspect] = useState([])
  const [astroEventsData, setAstroEventsData] = useState([])
  const [showAllAstrologyEvents, setShowAllAstrologyEvents] = useState(false)

  const [selectedAstroEvent, setSelectedAstroEvent] = useState(null)

  useEffect(() => {
    const fetchMoonData = async () => {
      try {
        if (mode === 'day') {
          const currentDate = date.format('YYYY-MM-DD')
          const response = await fetch(
            `${BASE_URL}/api/astro-events/astro-events.php?date=${currentDate}`,
          )
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          const data = await response.json()

          const tmpConjunctions = data.filter(
            (astroEvent) =>
              astroEvent.aspect === 'conjunction' ||
              astroEvent.aspect === 'in' ||
              astroEvent.aspect === 'entered' ||
              astroEvent.aspect === 'enters' ||
              astroEvent.aspect === 'Retrograde' ||
              astroEvent.aspect === 'left' ||
              astroEvent.aspect === 'Direct' ||
              astroEvent.aspect === 'leaves',
          )
          const tmpAnyMajorAspect = data.filter(
            (astroEvent) =>
              astroEvent.aspect === 'trine' ||
              astroEvent.aspect === 'sextile' ||
              astroEvent.aspect === 'opposition' ||
              astroEvent.aspect === 'square',
          )

          setConcjunctions(tmpConjunctions)
          setAnyMajorAspect(tmpAnyMajorAspect)

          setAstroEventsData(data)
        }
        if (mode === 'month') {
          const response = await fetch(
            `${BASE_URL}/api/astro-events/astro-events.php?month=${date.format(
              'MM',
            )}&&year=${date.format('YYYY')}`,
          )
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          const data = await response.json()

          const tmpConjunctions = data.filter(
            (astroEvent) =>
              astroEvent.aspect === 'conjunction' ||
              astroEvent.aspect === 'in' ||
              astroEvent.aspect === 'entered' ||
              astroEvent.aspect === 'enters' ||
              astroEvent.aspect === 'Retrograde' ||
              astroEvent.aspect === 'left' ||
              astroEvent.aspect === 'Direct' ||
              astroEvent.aspect === 'leaves',
          )
          const tmpAnyMajorAspect = data.filter(
            (astroEvent) =>
              astroEvent.aspect === 'trine' ||
              astroEvent.aspect === 'sextile' ||
              astroEvent.aspect === 'opposition' ||
              astroEvent.aspect === 'square',
          )

          setConcjunctions(tmpConjunctions)
          setAnyMajorAspect(tmpAnyMajorAspect)

          setAstroEventsData(data)
        }
      } catch (error) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchMoonData()
  }, [date])

  const getAstroEvent = async (astroEvent) => {
    console.log(astroEvent)
    let astoEventExplenation = await getAstroEventExplaination(astroEvent)
    console.log(astoEventExplenation)
    setSelectedAstroEvent(astoEventExplenation)
  }

  if (loading) {
    return <div></div>
  }

  if (error) {
    return <div></div>
  }

  function mapSignToEmoji(sign) {
    switch (sign) {
      case 'aries':
        return '♈️'
      case 'taurus':
        return '♉️'
      case 'gemini':
        return '♊️'
      case 'cancer':
        return '♋️'
      case 'leo':
        return '♌️'
      case 'virgo':
        return '♍️'
      case 'libra':
        return '♎️'
      case 'scorpio':
        return '♏️'
      case 'sagittarius':
        return '♐️'
      case 'capricorn':
        return '♑️'
      case 'aquarius':
        return '♒️'
      case 'pisces':
        return '♓️'
      default:
        return ''
    }
  }

  return (
    <StyledAstroEvents>
      <div className="animated fadeIn">
        <SectionHeader
          title={t('astro-events.headline')}
          text={t('astro-events.subheadline')}
        ></SectionHeader>
      </div>

      <TagCloud
        items={selection}
        selectedItem={selectedView}
        onClick={(item) => {
          setSelectedView(item)
        }}
        propertyName="headline"
      ></TagCloud>

      {selectedView.headline === t('astro-events.core-events') && (
        <>
          {concjunctions.map((astroEvent, index) => (
            <ContentCard
              key={'astroEvent' + index}
              circleText={`${dayjs(astroEvent.date).format('DD')}<br />${t(
                dayjs(astroEvent.date).format('MMMM').toLowerCase(),
              ).slice(0, 3)}`}
              title={`${t(astroEvent.firstPlanet)} ${t(astroEvent.aspect)} ${t(
                astroEvent.secondPlanet,
              )} ${astroEvent.isRetrograde ? t('retrograde') : ''}`}
              onClick={() => {
                getAstroEvent(
                  `${astroEvent.firstPlanet} ${astroEvent.aspect} ${astroEvent.secondPlanet} ${
                    astroEvent.isRetrograde ? ',retrograde' : ''
                  }`,
                )
              }}
            ></ContentCard>
          ))}
        </>
      )}

      {selectedView.headline === t('astro-events.secondary-aspects') && (
        <>
          {anyMajorAspect.map((astroEvent, index) => (
            <ContentCard
              key={'astroEvent' + index}
              circleText={`${dayjs(astroEvent.date).format('DD')}<br />${t(
                dayjs(astroEvent.date).format('MMMM').toLowerCase(),
              ).slice(0, 3)}`}
              title={`${t(astroEvent.firstPlanet)} ${t(astroEvent.aspect)} ${t(
                astroEvent.secondPlanet,
              )} ${astroEvent.isRetrograde ? t('retrograde') : ''}`}
              onClick={() => {
                getAstroEvent(
                  `${astroEvent.firstPlanet} ${astroEvent.aspect} ${astroEvent.secondPlanet} ${
                    astroEvent.isRetrograde ? ',retrograde' : ''
                  }`,
                )
              }}
            ></ContentCard>
          ))}
        </>
      )}

      {selectedView.headline === t('astro-events.all') && (
        <>
          {astroEventsData.map((astroEvent, index) => (
            <ContentCard
              key={'astroEvent' + index}
              circleText={`${dayjs(astroEvent.date).format('DD')}<br />${t(
                dayjs(astroEvent.date).format('MMMM').toLowerCase(),
              ).slice(0, 3)}`}
              title={`${t(astroEvent.firstPlanet)} ${t(astroEvent.aspect)} ${t(
                astroEvent.secondPlanet,
              )} ${astroEvent.isRetrograde ? t('retrograde') : ''}`}
              onClick={() => {
                getAstroEvent(
                  `${astroEvent.firstPlanet} ${astroEvent.aspect} ${astroEvent.secondPlanet} ${
                    astroEvent.isRetrograde ? ',retrograde' : ''
                  }`,
                )
              }}
            ></ContentCard>
          ))}
        </>
      )}

      {selectedAstroEvent != null && (
        <Modal
          onClose={() => {
            setSelectedAstroEvent(null)
          }}
        >
          <StoryPage
            title={selectedAstroEvent[0].title}
            text={selectedAstroEvent[0].text}
            handleClose={() => {
              setSelectedAstroEvent(null)
            }}
          ></StoryPage>
        </Modal>
      )}
    </StyledAstroEvents>
  )
}
