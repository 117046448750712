import React, { useState, useEffect } from 'react';
import * as dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { API_URL, BASE_URL } from 'constants/URLS';
import { getZodiacSign } from 'helper/getZodiac';
import { useAPI } from 'context/APIcontext';
import BlockButton from 'components/buttons/BlockButton';
import Lottie from 'react-lottie';
import * as animationData from 'assets/lottie/animation_onboarding_splash.json';
import PageTitle from 'components/PageTitle';
import BottomSpacer from 'components/BottomSpacer';
import styled from 'styled-components';

const StyledAddFriend = styled.div`
  margin-top: 60px;
`;

export default function AddFriend({ onFriendAdded }) {
  const { t } = useTranslation('common');

  const { addFriend } = useAPI();

  const [personName, setPersonName] = useState('');
  const [locationSearchTerm, setLocationSearchTerm] = useState('');
  const [locationSearchResults, setLocationSearchResults] = useState([]);
  const [location, setLocation] = useState(null);
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('0');
  const [year, setYear] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [timeknown, setTimeKnown] = useState(false);
  const [personNameError, setPersonNameError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  const searchLocation = () => {
    fetch(`${API_URL}/api/searchlocation.php?term=${encodeURI(locationSearchTerm)}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setLocationSearchResults(data);
      })
      .catch((error) => {
        console.error('Error searching location:', error);
      });
  };

  const selectLocation = (location) => {
    fetch(`${API_URL}/api/searchlocation.php?place_id=${encodeURI(location.id)}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setLocation(data);
        setLocationSearchTerm(location.label);
        setLocationSearchResults([]);
      })
      .catch((error) => {
        console.error('Error selecting location:', error);
      });
  };

  const clearPersonName = () => {
    setPersonName('');
  };

  const clearLocationSearchTerm = () => {
    setLocationSearchTerm('');
    setLocationSearchResults([]);
  };

  const clearDay = () => {
    setDay('');
  };

  const clearYear = () => {
    setYear('');
  };

  const clearHours = () => {
    setHours('');
  };

  const clearMinutes = () => {
    setMinutes('');
  };

  const checkDateAndLocation = () => {
    setPersonNameError(false);
    setLocationError(false);
    setDateError(false);
    setTimeError(false);

    if (!location) {
      setLocationError(true);
    }

    if (!personName || personName.length < 1) {
      setPersonNameError(true);
    }

    const dayNum = parseInt(day, 10);
    const monthNum = parseInt(month, 10);
    const yearNum = parseInt(year, 10);
    const dateString = `${yearNum}-${monthNum}-${dayNum}`;
    const date = dayjs(dateString);

    if (
      !date.isValid() ||
      date.date() !== dayNum ||
      date.month() + 1 !== monthNum ||
      date.year() !== yearNum
    ) {
      setDateError(true);
      return;
    }

    if (timeknown) {
      const hoursNum = parseInt(hours, 10);
      const minutesNum = parseInt(minutes, 10);
      const timeValid = hoursNum >= 0 && hoursNum < 24 && minutesNum >= 0 && minutesNum < 60;

      if (!timeValid) {
        setTimeError(true);
        return;
      }
    }

    if (!dateError && !timeError && !locationError && !personNameError) {
      saveFriend();
    }
  };

  const saveFriend = async () => {
    const zodiacSign = getZodiacSign(Number(day), Number(month));

    const friendsData = {
      isMe: false,
      name: personName,
      birthYear: year,
      birthMonth: month,
      birthDay: day,
      birthHour: hours,
      birthMinute: minutes,
      location: location,
      zodiacSign: zodiacSign,
    };

    addFriend(friendsData);
    onFriendAdded();
  };

  return (
    <StyledAddFriend>
      <Lottie
        options={defaultOptions}
        height={200}
        width={200}
        isStopped={defaultOptions.isStopped}
        isPaused={defaultOptions.isPaused}
      />
      <PageTitle title={t('friends.add-friend')} text={t('friends.add-friend-text')}></PageTitle>

      <div className="input-box animated fadeIn">
        <div className="input-box-headline">{t('friends.name-of-person')}</div>
        <div className="input-box-input-wrapper">
          <input
            id="birthlocation"
            type="text"
            inputMode="text"
            value={personName}
            onChange={(e) => setPersonName(e.target.value)}
            onClick={clearPersonName}
            className={`input-box-input ${personNameError ? 'is-error' : ''}`}
            placeholder={t('friends.name')}
          />
        </div>
      </div>

      <div className="input-box animated fadeIn" style={{ zIndex: 9999 }}>
        <div className="input-box-headline">{t('friends.birth-location-headline')}</div>
        <div className="input-box-input-wrapper">
          <input
            id="birthlocation"
            type="text"
            value={locationSearchTerm}
            onChange={(e) => {
              setLocationSearchTerm(e.target.value);
              if (e.target.value.length > 2) {
                searchLocation(e.target.value);
              }
            }}
            onClick={clearLocationSearchTerm}
            className={`input-box-input ${locationError ? 'is-error' : ''}`}
            placeholder={t('friends.birth-location')}
          />
          {locationSearchResults.length > 0 && (
            <div className="birthlocation-entry">
              {locationSearchResults.map((location, index) => {
                return (
                  <div key={location + index}>
                    <div
                      className="birthlocation-entry-item"
                      onClick={() => selectLocation(location)}
                    >
                      {location.label}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className="input-box animated fadeIn">
        <div className="input-box-headline">{t('friends.birthdate-headline')}</div>
        <div className="input-box-input-wrapper">
          <input
            id="number"
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            value={day}
            onChange={(e) => setDay(e.target.value)}
            onClick={clearDay}
            className={`birthday-day ${dateError ? 'is-error' : ''}`}
            placeholder={t('DD')}
          />
          <select
            id="month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            className={`birthday-month ${dateError ? 'is-error' : ''}`}
          >
            <option value="0" disabled>
              {t('MM')}
            </option>
            <option value="1">{t('january')}</option>
            <option value="2">{t('february')}</option>
            <option value="3">{t('march')}</option>
            <option value="4">{t('april')}</option>
            <option value="5">{t('may')}</option>
            <option value="6">{t('june')}</option>
            <option value="7">{t('july')}</option>
            <option value="8">{t('august')}</option>
            <option value="9">{t('september')}</option>
            <option value="10">{t('october')}</option>
            <option value="11">{t('november')}</option>
            <option value="12">{t('december')}</option>
          </select>

          <input
            id="number"
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            onClick={clearYear}
            className={`birthday-year ${dateError ? 'is-error' : ''}`}
            placeholder={t('YYYY')}
          />
        </div>

        <div
          className="input-box-input-wrapper is-centered"
          style={{ width: '340px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <input
            type="checkbox"
            id="birthtime-checkbox"
            checked={timeknown}
            onChange={(e) => setTimeKnown(e.target.checked)}
          />
          <label htmlFor="birthtime-checkbox" className="birthtime-known">
            {t('friends.i-know-the-exact-birth-time')}
          </label>
        </div>

        {timeknown && (
          <div className="input-box-input-wrapper is-centered">
            <div className="birthtime">
              <input
                id="number"
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                value={hours}
                onChange={(e) => setHours(e.target.value)}
                onClick={clearHours}
                className={`birthtime-hours ${timeError ? 'is-error' : ''}`}
                placeholder={t('hour')}
              />
              <input
                id="number"
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                value={minutes}
                onChange={(e) => setMinutes(e.target.value)}
                onClick={clearMinutes}
                className={`birthtime-minutes ${timeError ? 'is-error' : ''}`}
                placeholder={t('minute')}
              />
            </div>
          </div>
        )}
      </div>

      <BottomSpacer />
      <BlockButton
        text={t('friends.add-person')}
        isBottom
        onClick={() => {
          checkDateAndLocation();
        }}
      ></BlockButton>
    </StyledAddFriend>
  );
}
