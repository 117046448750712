import React from 'react';
import styled from 'styled-components';

const StyledLightButton = styled.div`
  background: rgb(234, 238, 247);
  border: 2px solid rgb(234, 238, 247);
  color: #111;
  text-align: center;
  padding: 14px 10px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  font-family: 'clever-german', serif;
  font-size: 16px;
  transition: all 0.5s ease;
  max-width: 720px;
  margin: 20px 3% 0;
  box-shadow: rgba(84, 60, 151, 0.25) 2px 2px 6px 0px;
  display: inline-block;

  @media (min-width: 720px) {
    margin: 20px auto 0;
  }

  &.is-bottom {
    left: 50%;
    position: fixed;
    bottom: 30px;
    margin-bottom: var(--ion-safe-area-bottom, 0);
    margin: 0 auto;
    width: 94%;
    max-width: 536px;
    transform: translate(-50%, 0);
    z-index: 30;
    background: rgb(234, 238, 247);
    border: 2px solid rgb(234, 238, 247);

    @media (min-width: 768px) {
    }
  }

  &.is-bottom-second {
    left: 50%;
    position: fixed;
    bottom: 110px;
    margin-bottom: var(--ion-safe-area-bottom, 0);
    margin: 0 auto;
    width: 94%;
    max-width: 536px;
    transform: translate(-50%, 0);
    z-index: 30;

    @media (min-width: 768px) {
    }
  }

  &.is-disabled {
    background-color: #e0e0e0;
    border: 1px solid #e0e0e0;
    color: #fff;
    cursor: not-allowed;
  }

  &.no-border {
    border: 0 none;
    text-decoration: underline;
  }

  &.full-width {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
  }

  &.is-solid {
    background: rgb(234, 238, 247);
    color: #fff;
  }

  &.no-margin {
    margin: 0;
  }

  &.is-outline {
    background-color: transparent;
    color: #c19c7b;
  }
`;

export default function LightButton({
  text,
  disabled,
  onClick,
  isOutlined = false,
  isBottom = false,
  isSolid = false,
  noBorder = false,
  isBottomSecond = false,
  noMargin = false,
}) {
  function handleOnClick() {
    if (disabled) return;
    onClick();
  }

  return (
    <StyledLightButton
      className={`${disabled ? 'is-disabled' : ''} ${isOutlined ? 'is-outline' : ''} ${
        noMargin ? 'no-margin' : ''
      } ${isBottom ? 'is-bottom' : ''}${isBottomSecond ? 'is-bottom-second' : ''} ${
        isSolid ? 'is-solid' : ''
      } ${noBorder ? 'no-border' : ''}`}
      onClick={() => {
        handleOnClick();
      }}
    >
      {text}
    </StyledLightButton>
  );
}
