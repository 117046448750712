export default function convertToDecimalCoordinates(data) {
  let latitudeDegrees = data.location.latitude_degrees;
  let latitudeMinutes = data.location.latitude_minutes;
  let longitudeDegrees = data.location.longitude_degrees;
  let longitudeMinutes = data.location.longitude_minutes;

  // Convert to decimal
  let decimalLatitude = latitudeDegrees + latitudeMinutes / 60;
  let decimalLongitude = longitudeDegrees + longitudeMinutes / 60;

  // Assuming the direction fields are not used because Berlin is in the NE quadrant
  // If you had proper direction indicators, you might need to adjust signs here

  return {
    decimalLatitude: decimalLatitude,
    decimalLongitude: decimalLongitude,
  };
}
