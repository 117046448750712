export const BASE_URL = 'https://tinyaffirmation.com';
export const ASSETS_URL = `${BASE_URL}/react/assets`;

export const API_URL = 'https://tinyaffirmation.com';

export const LANDING_PAGE_PURCHASE = `${BASE_URL}/register/free/`;

export const PLAN_API = `${BASE_URL}/api-current-plan/`;
export const DATA_FOLDER = `${BASE_URL}/`;
export const HOROSCOPE_API = `${BASE_URL}/api/horoscope/get.php`;
export const MOON_DATA_API = `${BASE_URL}/api/moon/getCurrent.php`;
export const ARTICLES_DATA_API = `${BASE_URL}/api/learn/tarot.php`;
export const LEARN_TAROT_API = `${BASE_URL}/api/learn/tarot.php`;
export const SOUNDS_DATA_API = `${BASE_URL}/api/sounds/get.php`;
export const BIRTHCHART_API = `${BASE_URL}/api/birthchart/get.php`;
export const CHART_EXPLANATION_API = `${BASE_URL}/api/explanations/getChartExplanation.php`;
export const HOROSCOPE_SHORT_API = `${BASE_URL}/api/explanations/getDailyShortHoroscope.php`;
export const HOROSCOPE_EXPLANATIONS_API = `${BASE_URL}/api/explanations/getDailyHoroscopeExplanations.php`;
export const MOON_EXPLANATIONS_API = `${BASE_URL}/api/explanations/getMoonExplanations.php`;
export const TAROT_EXPLANATIONS_API = `${BASE_URL}/api/explanations/getTarotCardExplanation.php`;
export const TAROT_AI_READING_API = `${BASE_URL}/api/explanations/getAITarotReading.php`;
export const AFFIRMATIONS_API = `${BASE_URL}/api/affirmations/get.php`;
export const TAROT_CARD_API = `${BASE_URL}/api/tarot/tarotcards`;
export const CHALLENGES_API = `${BASE_URL}/api/challenges/get.php`;
export const BIRTHCHART_FAQ = `${BASE_URL}/api/birthchart/faq.php`;
