import BlockButton from 'components/buttons/BlockButton'
import React from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

const StyledHomePageTitle = styled.div`
  //font-family: "Libre Baskerville", serif;
  font-family: 'clever-german', serif;
  //font-family: "breathing", sans-serif;
  padding: 20px 3% 0;
  line-height: 1 !important;
  font-size: 40px;
  font-weight: 700 !important;
  color: #111;
  text-align: center;
  position: relative;
  margin: 0 30px 0;
  //color: #c19c7b;
  max-width: 720px;

  @media (min-width: 720px) {
    margin: 0 auto 20px;
  }

  ng-lottie {
    margin: 0 auto 20px;
    text-align: center;

    div {
      margin: 0 auto;
      text-align: center;
    }
  }

  .stats.overlay {
  }

  .page-title-big {
    margin-bottom: 6px;
  }

  .page-title-small {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.4;
    color: #111;
    min-height: 23px;
  }

  .day-change-button {
    padding: 0;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    font-family: 'clever-german', serif;
    font-size: 16px;
    color: #c19c7b;
    text-decoration: underline;

    &.is-prev-day {
      top: -65px;
      position: absolute;
      left: 0;
    }

    &.is-next-day {
      top: -65px;
      position: absolute;
      right: 0;
    }
  }
`

export default function HomePageTitle({ topText, title, text, date, onDateChange }) {
  const { t } = useTranslation('common')

  function createMarkup() {
    return { __html: text }
  }

  return (
    <StyledHomePageTitle>
      <div
        className="day-change-button is-prev-day"
        onClick={() => onDateChange(date.subtract(1, 'day'))}
      >
        {t(`prev-day`)}
      </div>

      <div
        className="day-change-button is-next-day"
        onClick={() => onDateChange(date.add(1, 'day'))}
      >
        {t(`next-day`)}
      </div>

      <div className="page-title-small animated fadeIn">
        <div className="page-tite-text">{topText}</div>
      </div>
      <div className="page-title-big animated fadeIn">{title}</div>
      {text && (
        <div className="page-title-small animated fadeIn">
          <div className="page-tite-text" dangerouslySetInnerHTML={createMarkup()}></div>
        </div>
      )}
    </StyledHomePageTitle>
  )
}
