import { BASE_URL } from 'constants/URLS';
import React from 'react';

import styled from 'styled-components';
import CheckmarkCircleIcon from './SVG/CheckmarkCircleIcon';
import LockClosedIcon from './SVG/LockClosedIcon';
import ChevronForwardIcon from './SVG/ChevronForwardIcon';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const StyledContentCardBig = styled.div`
  position: relative;
  margin: 0;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  padding-bottom: 20px;
  padding: 0;
  cursor: pointer;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  border-bottom: 1px solid whitesmoke;

  @media (min-width: 720px) {
    margin: 0 20px 0 0;
    overflow: hidden;
  }

  .content-card-buttom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .content-card-image-wrapper {
    margin-bottom: 10px;

    &.is-tarot-spread {
      padding: 10px;
      border: 2px solid #eee;
      background-color: #fff;

      .content-card-image {
        padding-top: 56.25%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        font-size: 24px;
        position: relative;
        margin-right: 0;
      }
    }

    .content-card-image {
      height: 0;
      padding-top: 56.25%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      font-size: 24px;
      position: relative;
      margin-right: 0;
      overflow: hidden;
      border: 1px solid whitesmoke;
      border-radius: 10px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .content-card-icon {
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 20px;
    min-width: 20px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      margin: 0 auto;
      display: inline-block;
      fill: rgba(51, 51, 51, 0.47);
      color: rgba(51, 51, 51, 0.47);
    }
  }

  .content-card-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0;
    width: 75%;
    line-height: 1.3;
    width: 80%;

    h4 {
      font-size: 14px;
      margin: 0 0 2px;
      color: #111;
      font-weight: 600;
      line-height: 1.2;
      width: 100%;
      font-family: 'Poppins', sans-serif;
      white-space: nowrap;

      span {
        white-space: nowrap;
      }
    }

    p {
      margin: 0;
      font-size: 14px;
      color: #111;
      color: rgba(51, 51, 51, 0.47);
      font-family: 'Open Sans', sans-serif;
      //color: #adadb3;
      line-height: 1.4;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      span {
        white-space: nowrap;
      }
    }

    .task-star {
      font-size: 12px;
      font-weight: 700;

      img {
        width: 10px;
      }
    }
  }
`;

export default function ContentCardBig({
  title,
  text,
  isLocked = false,
  onClick,
  image,
  isImageReversed,
  isTarotSpread = false,
}) {
  return (
    <StyledContentCardBig
      onClick={onClick}
      className={`content-card ${isLocked ? 'is-locked' : ''}`}
    >
      <div className={`content-card-image-wrapper ${isTarotSpread ? 'is-tarot-spread' : ''}`}>
        <div
          className="content-card-image"
          style={{
            transform: isImageReversed ? 'rotate(180deg)' : '',
            backgroundImage: `url(${BASE_URL}/api/images/placeholder.jpg)`,
          }}
        >
          <LazyLoadImage src={image} />
        </div>
      </div>

      <div className="content-card-buttom">
        <div className="content-card-text">
          <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
          <p dangerouslySetInnerHTML={{ __html: text }}></p>
        </div>
        <div className="content-card-icon">
          {isLocked ? <LockClosedIcon /> : <ChevronForwardIcon />}
        </div>
      </div>
    </StyledContentCardBig>
  );
}
