import { useState, useEffect } from 'react';

const useSwipeDetection = (onSwipeLeft, onSwipeRight) => {
  const [initialTouchX, setInitialTouchX] = useState(null);

  const handleStart = (e) => {
    if (e.touches && e.touches.length > 0) {
      setInitialTouchX(e.touches[0].clientX);
    } else {
      setInitialTouchX(e.clientX);
    }
  };

  const handleEnd = (e) => {
    if (initialTouchX !== null) {
      const finalTouchX = e.touches ? e.changedTouches[0].clientX : e.clientX;
      const swipeDistance = finalTouchX - initialTouchX;
      if (swipeDistance > 50) {
        onSwipeRight();
      } else if (swipeDistance < -50) {
        onSwipeLeft();
      }
      setInitialTouchX(null);
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleStart);
    document.addEventListener('touchend', handleEnd);
    document.addEventListener('mousedown', handleStart);
    document.addEventListener('mouseup', handleEnd);
    return () => {
      document.removeEventListener('touchstart', handleStart);
      document.removeEventListener('touchend', handleEnd);
      document.removeEventListener('mousedown', handleStart);
      document.removeEventListener('mouseup', handleEnd);
    };
  }, [onSwipeLeft, onSwipeRight]);

  return {
    handleStart,
    handleEnd,
  };
};

export default useSwipeDetection;
