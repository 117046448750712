import App from './App';
import { I18nextProvider } from 'react-i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import common_de from './translations/de.json';
import common_en from './translations/en.json';
import i18next from 'i18next';
import { getLanguage } from 'helper/getLanguage';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: getLanguage(), // language to use
  resources: {
    en: {
      common: common_en,
    },
    de: {
      common: common_de,
    },
  },
});

// Find all widget divs
const widgetDivs = document.querySelectorAll('.app-widget');

// Inject our React App into each class
widgetDivs.forEach((div) => {
  const root = ReactDOM.createRoot(div);
  root.render(
    <I18nextProvider i18n={i18next}>
      <App data={div.dataset} />
    </I18nextProvider>,
  );
});
