import React from 'react';

import styled from 'styled-components';

const StyledBottomSpacer = styled.div`
  height: 100px;
`;

export default function BottomSpacer() {
  return <StyledBottomSpacer className="hide-to-share"></StyledBottomSpacer>;
}
