import AlertDialog from 'components/AlertDialog';
import React, { createContext, useState, useContext } from 'react';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    headline: '',
    message: '',
    isOpen: false,
    onConfirm: null,
    onCancel: null,
  });

  const showAlert = (headline, message, onConfirm, onCancel) => {
    setAlert({ headline, message, isOpen: true, onConfirm, onCancel });
  };

  const closeAlert = () => {
    setAlert({ ...alert, isOpen: false });
  };

  return (
    <AlertContext.Provider value={{ alert, showAlert, closeAlert }}>
      {children}
      {alert.isOpen && (
        <AlertDialog
          headline={alert.headline}
          message={alert.message}
          onClose={closeAlert}
          onConfirm={alert.onConfirm}
          onCancel={alert.onCancel}
        />
      )}
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
