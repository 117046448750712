import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import HourglassOutlineIcon from './SVG/HourglassOutlineIcon';

const StyledSeeYouTomorrow = styled.div`
  margin: 30px 3% 100px;
  text-align: center;
  padding-top: 30px;

  &.is-full-height {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    max-width: 580px;
    margin: 0 auto;
  }

  .see-you-tomorrow-icon {
    width: 80px;
    height: 80px;
    background-color: #222838;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto 20px;
    font-size: 40px;
    color: #fff;

    svg {
      fill: #fff;
      height: 40px;
      width: 40px;
    }
  }

  .see-you-tomorrow-animation {
    width: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
  }

  h2 {
    font-family: 'clever-german', serif;
    color: #111;
    max-width: 70%;
    margin: 0 auto 10px;
  }

  span {
    display: block;
    margin: 0 auto;
    font-size: 15px;
    line-height: 1.6;
    color: #111;
    max-width: 70%;
  }
`;

export default function SeeYouTomorrow() {
  const { t } = useTranslation('common');

  return (
    <StyledSeeYouTomorrow>
      <div className="see-you-tomorrow-icon">
        <HourglassOutlineIcon></HourglassOutlineIcon>
      </div>
      <h2>{t('SEE_YOU_TOMORROW')}</h2>
      <span>{t('SEE_YOU_TOMORROW_TEXT')}</span>
    </StyledSeeYouTomorrow>
  );
}
