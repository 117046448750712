import React, { useEffect, useState, useContext } from 'react'
import { useAPI } from 'context/APIcontext'
import OnboardingPage from 'pages/OnboardingPage/OnboardingPage'
import DesktopNavigation from 'components/DesktopNavigation/DesktopNavigation'
import styled from 'styled-components'
import HomePage from 'pages/HomePage/HomePage'
import AffirmationPage from 'pages/AffirmationPage/AffirmationPage'
import TarotPage from 'pages/TarotPage/TarotPage'
import WisdomPage from 'pages/WisdomPage/WisdomPage'
import MeditationPage from 'pages/MeditationPage/MeditationPage'
import LoadingState from 'components/LoadingState'
import { useTranslation } from 'react-i18next'
import { BASE_URL } from 'constants/URLS'
import MoonCalendar from 'pages/MoonCalendar/MoonCalendar'
import { getLanguage } from 'helper/getLanguage'
import ProfilePage from 'pages/ProfilePage/ProfilePage'
import FriendsPage from 'pages/FriendsPage/FriendsPage'

export const StyledMainContent = styled.div``

export default function OverviewPage({ startView = 'home' }) {
  const { t } = useTranslation('common')

  const { userData } = useAPI()

  const [isLoading, setIsLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(startView)

  const [overViewTourCompleted] = useState(
    localStorage.getItem('mealPlanDaysTourCompleted') === 'true',
  )

  const isLocalhost =
    window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [currentTab])

  return (
    <div>
      <div className="hide-to-share">
        <DesktopNavigation
          currentTab={currentTab}
          handleTabChange={(tab) => {
            if (isLocalhost) {
              setCurrentTab(tab)
            } else {
              setCurrentTab(tab)
              /*setIsLoading(true);
                  setTimeout(() => {
                    setCurrentTab(tab);
                    window.location.href = `${BASE_URL}/${getLanguage()}/${tab}`;
                  }, 200);*/
            }
          }}
        ></DesktopNavigation>
      </div>
      {isLoading ? (
        <>
          {' '}
          <LoadingState
            title={t('page-is-loading')}
            text={t('page-is-loading-text')}
          ></LoadingState>
        </>
      ) : (
        <>
          {currentTab === 'home' && <HomePage setCurrentTab={setCurrentTab}></HomePage>}
          {currentTab === 'friends' && <FriendsPage></FriendsPage>}
          {currentTab === 'tarot' && <TarotPage></TarotPage>}
          {currentTab === 'reading' && <WisdomPage></WisdomPage>}
          {currentTab === 'moon-calendar' && <MoonCalendar></MoonCalendar>}
          {currentTab === 'me' && <ProfilePage></ProfilePage>}
        </>
      )}
    </div>
  )
}
