import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import Lottie from 'react-lottie';
import * as animationData from 'assets/lottie/animation_onboarding_name.json';
import BlockButton from 'components/buttons/BlockButton';
import FemaleIcon from 'components/SVG/FemaleIcon';
import MaleIcon from 'components/SVG/MaleIcon';
import NonBinaryIcon from 'components/SVG/NonBinaryIcon';

export default function GenderStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  gender,
  setGender,
}) {
  const { t } = useTranslation('common');

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  return (
    <div>
      <div className="animated fadeIn">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={defaultOptions.isStopped}
          isPaused={defaultOptions.isPaused}
        />
      </div>
      <span className="onboarding-headline animated fadeIn">
        {t('welcome-page.step-gender-headline')}
      </span>
      <span className="onboarding-text animated fadeIn">{t('welcome-page.step-gender-text')}</span>

      <div className="icon-select-wrapper animated fadeIn">
        <div
          className={`icon-select ${gender === 'female' ? 'is-active' : ''}`}
          onClick={() => {
            setGender('female');
          }}
        >
          <FemaleIcon></FemaleIcon>
          <span>{t('female')}</span>
        </div>

        <div
          className={`icon-select ${gender === 'male' ? 'is-active' : ''}`}
          onClick={() => {
            setGender('male');
          }}
        >
          <MaleIcon></MaleIcon>
          <span>{t('male')}</span>
        </div>

        <div
          className={`icon-select ${gender === 'non-binary' ? 'is-active' : ''}`}
          onClick={() => {
            setGender('non-binary');
          }}
        >
          <NonBinaryIcon></NonBinaryIcon>
          <span>{t('non-binary')}</span>
        </div>
      </div>

      <BlockButton
        isBottom
        disabled={!gender}
        text={t('continue')}
        onClick={() => {
          scrollToWidget();
          setCurrentStep(currentStep + 1);
        }}
      ></BlockButton>
    </div>
  );
}
