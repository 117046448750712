import React from 'react';
import { BASE_URL } from 'constants/URLS';
import html2canvas from './html2canvas';
import { v4 as uuidv4 } from 'uuid';

const hideElements = () => {
  const elementsToHide = document.querySelectorAll('.hide-to-share, .slick-dots');
  elementsToHide.forEach((element) => {
    if (element instanceof HTMLElement) {
      element.style.visibility = 'hidden';
    }
  });

  const elementsToShow = document.querySelectorAll('.show-to-share');
  elementsToShow.forEach((element) => {
    if (element instanceof HTMLElement) {
      element.style.visibility = 'visible';
    }
  });

  document.querySelectorAll('.animation.animated').forEach((element) => {
    element.classList.remove('animated');
  });

  document.querySelectorAll('.animation.fadeIn').forEach((element) => {
    element.classList.remove('fadeIn');
  });
};

const showElements = () => {
  const elementsToShow = document.querySelectorAll('.hide-to-share, .slick-dots');
  elementsToShow.forEach((element) => {
    if (element instanceof HTMLElement) {
      element.style.visibility = 'visible';
    }
  });

  const elementsToHide = document.querySelectorAll('.show-to-share');
  elementsToHide.forEach((element) => {
    if (element instanceof HTMLElement) {
      element.style.visibility = 'hidden';
    }
  });
};

function generateUniqueFilename() {
  // Generate a UUID (Universally Unique Identifier)
  const uuid = uuidv4();

  // Append a prefix or suffix to make the filename more meaningful if needed
  const filename = `img_${uuid}`;

  return filename;
}

async function uploadImage(imageBase64, filename) {
  const endpoint = '${BASE_URL}/api/images/shareImage.php';
  const body = { image: imageBase64, filename: filename }; // Include filename in the request body

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
}

export default async function screenshotShare() {
  try {
    hideElements();

    const screenShotContentAll = document.querySelectorAll('[data-id="screenshot-area"]');
    const wrapperElementAll = document.querySelectorAll('[data-id="screenshot-wrapper"]');
    const screenShotAreaAll = document.querySelectorAll('[data-id="modal-content"]');

    const screenShotContent = screenShotContentAll[screenShotContentAll.length - 1];
    const wrapperElement = wrapperElementAll[wrapperElementAll.length - 1];
    const screenShotArea = screenShotAreaAll[screenShotAreaAll.length - 1];

    /*document
      .querySelectorAll('.affirmation-text b')
      .forEach((element) => (element.style.top = '-14px'));*/

    // Determine the width and height based on the device type
    let width = 430;
    let height = 932;

    // Resize the viewport, wrapper element, and body temporarily
    screenShotContent.style.background = 'linear-gradient(130deg, #fff, #f8efea)';
    screenShotContent.style.height = `${height}px`;
    screenShotContent.style.minHeight = `${height}px`;
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute('content', `width=${width}, height=${height}, initial-scale=1`);
    if (wrapperElement) {
      wrapperElement.style.width = `${width}px`;
      wrapperElement.style.height = `${height}px`;
    }
    if (screenShotArea) {
      screenShotArea.style.width = `${width}px`;
      screenShotArea.style.height = `${height}px`;
      screenShotArea.style.minHeight = `${height}px`;
      screenShotArea.style.paddingTop = '0px';
    }
    document.body.style.width = `${width}px`;
    document.body.style.height = `${height}px`;
    document.body.style.overflow = 'hidden'; // Prevent scrolling

    // Wait for a short time to ensure the page is re-rendered with the new viewport size
    await new Promise((resolve) => setTimeout(resolve, 500));

    const canvas = await html2canvas(screenShotContent, {
      useCORS: true,
      width: screenShotContent.offsetWidth,
      height: screenShotContent.offsetHeight,
      scrollX: 0,
      scrollY: 0,
      scale: Math.min(window.devicePixelRatio, 2),
    });

    const uniqueFilename = generateUniqueFilename();

    const dataUrl = canvas.toDataURL(); // Convert canvas to data URL

    //const responseData = await uploadImage(dataUrl, uniqueFilename);

    const blob = await fetch(dataUrl).then((res) => res.blob());
    const file = new File([blob], 'screenshot.png', { type: blob.type });
    const fileUrl = URL.createObjectURL(file);

    const returnData = {
      dataUrl: dataUrl,
      blob: blob,
      file: file,
      //remoteURL: responseData.url,
      fileUrl: fileUrl,
    };

    showElements();

    // Reset the viewport, wrapper element, and body sizes and overflow
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute('content', 'width=device-width, initial-scale=1');
    screenShotContent.removeAttribute('style');
    if (wrapperElement) {
      wrapperElement.removeAttribute('style');
    }
    if (screenShotArea) {
      screenShotArea.removeAttribute('style');
    }
    document.body.style.width = 'auto';
    document.body.style.height = 'auto';
    document.body.style.overflow = 'visible';

    document
      .querySelectorAll('.affirmation-text b')
      .forEach((element) => element.removeAttribute('style'));

    //share('Shared Screenshot', 'Check out this screenshot!', blob, fileUrl);

    /*
    if (navigator.share && !window.location.hostname.includes('localhost')) {
      await navigator.share({
        title: 'Shared Screenshot',
        text: 'Check out this screenshot!',
        url: `${responseData.url}`, // Use File URL instead of Blob URL
      });
    } else {
      console.warn('Native share not supported or running on localhost');
      const shared = prompt('Enter the URL to share:', responseData.url);
      if (shared) {
        console.log('Shared:', shared);
        // Handle sharing with the entered URL
      } else {
        console.log('Sharing cancelled');
        // Handle cancellation
      }

      // Open image in new tab
      const newTab = window.open();
      newTab.document.write(`<img src="${fileUrl}" alt="Screenshot"/>`);
    }*/

    return returnData;
  } catch (error) {
    console.error('Error capturing or sharing screenshot:', error);
  }
}

const share = async (title, text, blob, url) => {
  const data = {
    files: [
      new File([blob], 'image.png', {
        type: blob.type,
      }),
    ],
    title: title,
    text: text,
  };
  try {
    if (!navigator.canShare(data)) {
      throw new Error("Can't share data.", data);
    }
    await navigator.share(data);
  } catch (err) {
    console.error(err.name, err.message);
  }
};

function isiOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

const download = (image, { name = 'img', extension = 'jpg' } = {}) => {
  const a = document.createElement('a');
  a.href = image;
  a.download = createFileName(extension, name);
  a.click();
};
