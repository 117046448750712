export function getZodiacSign(day, month) {
  const zodiacSigns = [
    'capricorn', // 0
    'aquarius', // 1
    'pisces', // 2
    'aries', // 3
    'taurus', // 4
    'gemini', // 5
    'cancer', // 6
    'leo', // 7
    'virgo', // 8
    'libra', // 9
    'scorpio', // 10
    'sagittarius', // 11
  ];

  const lastDayOfSigns = [19, 18, 20, 19, 20, 20, 22, 22, 22, 22, 21, 21]; // Last day the sun is in a sign

  // Adjust month number for array indexing (0-11)
  month -= 1;

  if (day <= lastDayOfSigns[month]) {
    return zodiacSigns[month];
  } else {
    // Move to the next zodiac sign
    return zodiacSigns[(month + 1) % 12];
  }
}
