export const spreads = [
  {
    id: 'daily',
    title: 'Daily Reading',
    image: 'layout-3-horizontal.svg',
    question: 'How is my day going?',
    type: 'daily',
    notes: null,
    date: null,
    isFree: true,
    cards: [
      {
        card_id: null,
        name: 'Thinking',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Feeling',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Doing',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '50%' },
      },
    ],
  },
  {
    id: 'single',
    title: 'Single Card',
    image: 'layout-single.svg',
    type: 'daily',
    question: null,
    notes: null,
    date: null,
    isFree: true,
    cards: [
      {
        card_id: null,
        name: 'Your Answer',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
    ],
  },
  {
    id: 'ppf',
    title: 'Past, Present, Future',
    image: 'layout-3-horizontal.svg',
    type: 'general',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Past',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Present',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Future',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '50%' },
      },
    ],
  },
  {
    id: 'sao',
    title: 'Situation, Action, Outcome',
    image: 'layout-3-horizontal.svg',
    type: 'general',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Situation',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Action',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Outcome',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '50%' },
      },
    ],
  },
  {
    id: 'mbs',
    title: 'Mind, Body, Spirit',
    image: 'layout-3-balanced.svg',
    type: 'spiritual',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Mind',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Body',
        reversed: void 0,
        position: { x: 'calc(35%)', y: '75%' },
      },
      {
        card_id: null,
        name: 'Spirit',
        reversed: void 0,
        position: { x: 'calc(65%)', y: '75%' },
      },
    ],
  },
  {
    id: 'love-short',
    title: 'Relationship Spread',
    image: 'layout-3-horizontal.svg',
    type: 'love',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'You',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Dynamics',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Partner',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '50%' },
      },
    ],
  },
  {
    id: 'compatibility',
    title: 'Compatibility Spread',
    image: 'layout-hex.svg',
    type: 'love',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Your Wants',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Their Wants',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Differences',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Similarities',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Emotional Compatibility',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Physical Compatibility',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Mental Compatibility',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '90%' },
      },
    ],
  },
  {
    id: 'heartbreak',
    title: 'Broken Heart',
    image: 'layout-hex.svg',
    type: 'love',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Your Contribution',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Their Contribution',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Advice for You',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Advice for Them',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Other Influences',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Can It Be Saved?',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Worth Saving?',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '90%' },
      },
    ],
  },
  {
    id: '123',
    title: 'Three Options',
    image: 'layout-3-balanced.svg',
    type: 'general',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Option 1',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Option 2',
        reversed: void 0,
        position: { x: 'calc(35%)', y: '75%' },
      },
      {
        card_id: null,
        name: 'Option 3',
        reversed: void 0,
        position: { x: 'calc(65%)', y: '75%' },
      },
    ],
  },
  {
    id: 'self',
    title: 'Self Assessment and Advice',
    image: 'layout-3-balanced.svg',
    type: 'spiritual',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Weaknesses',
        reversed: void 0,
        position: { x: 'calc(35%)', y: '75%' },
      },
      {
        card_id: null,
        name: 'Strengths',
        reversed: void 0,
        position: { x: 'calc(65%)', y: '75%' },
      },
      {
        card_id: null,
        name: 'Advice',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '30%' },
      },
    ],
  },
  {
    id: 'dreams',
    title: 'Dream Interpretation',
    image: 'layout-3-balanced.svg',
    type: 'spiritual',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Origin',
        reversed: void 0,
        position: { x: 'calc(35%)', y: '75%' },
      },
      {
        card_id: null,
        name: 'Message',
        reversed: void 0,
        position: { x: 'calc(65%)', y: '75%' },
      },
      {
        card_id: null,
        name: 'Waking Life',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '30%' },
      },
    ],
  },
  {
    id: 'goal',
    title: 'Shooting Forward',
    image: 'layout-spear.svg',
    type: 'career',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Dream Job',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Path Forward',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Your Qualities',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '100%' },
      },
      {
        card_id: null,
        name: 'Sources of Help',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '100%' },
      },
      {
        card_id: null,
        name: 'Needs Attention',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '100%' },
      },
    ],
  },
  {
    id: 'work-issues',
    title: 'Work Problems',
    image: 'layout-cross-over.svg',
    type: 'career',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Goal',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Challenge',
        reversed: void 0,
        position: {
          x: 'calc(50%)',
          y: '50%',
          rotate: 'rotate(90deg)',
        },
      },
      {
        card_id: null,
        name: 'Pulls You Back',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Pushes You Forward',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Rewards',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Underlying Factors',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '100%' },
      },
    ],
  },
  {
    id: 'finances',
    title: 'Turbulent Finances',
    image: 'layout-pentagram.svg',
    type: 'career',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Root Cause',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Opposition',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Action',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Allies',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Sources of Help',
        reversed: void 0,
        position: { x: 'calc(35%)', y: '100%' },
      },
      {
        card_id: null,
        name: 'Outcome',
        reversed: void 0,
        position: { x: 'calc(65%)', y: '100%' },
      },
    ],
  },
  {
    id: 'love-ready',
    title: 'Readiness for Love',
    image: 'layout-pyramid.svg',
    type: 'love',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'The Love You Want',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'What Love Taught You',
        reversed: void 0,
        position: { x: 'calc(65%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'What Holds You Back',
        reversed: void 0,
        position: { x: 'calc(35%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Is Your Heart Ready',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '100%' },
      },
      {
        card_id: null,
        name: 'Is Your Mind Ready',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '100%' },
      },
      {
        card_id: null,
        name: 'Is Your Spirit Ready',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '100%' },
      },
    ],
  },
  {
    id: 'work-goals',
    title: 'Brick by Brick',
    image: 'layout-pyramid.svg',
    type: 'career',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Purpose',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '100%' },
      },
      {
        card_id: null,
        name: 'Motivation',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '100%' },
      },
      {
        card_id: null,
        name: 'Responsibilities',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '100%' },
      },
      {
        card_id: null,
        name: 'Your Projects',
        reversed: void 0,
        position: { x: 'calc(65%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Rewards',
        reversed: void 0,
        position: { x: 'calc(35%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Career Potential',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
    ],
  },
  {
    id: 'celtic',
    title: 'Celtic Cross',
    image: 'layout-celtic.svg',
    type: 'general',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Heart of the Matter',
        reversed: void 0,
        position: { x: 'calc(38%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Challenge',
        reversed: void 0,
        position: {
          x: 'calc(30%)',
          y: '55%',
          rotate: 'rotate(90deg)',
        },
      },
      {
        card_id: null,
        name: 'Unconscious',
        reversed: void 0,
        position: { x: 'calc(38%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Past',
        reversed: void 0,
        position: { x: 'calc(18%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Conscious',
        reversed: void 0,
        position: { x: 'calc(38%)', y: '20%' },
      },
      {
        card_id: null,
        name: 'Future',
        reversed: void 0,
        position: { x: 'calc(58%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Querent',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '95%' },
      },
      {
        card_id: null,
        name: 'Environment',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '65%' },
      },
      {
        card_id: null,
        name: 'Hopes and Fears',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '35%' },
      },
      {
        card_id: null,
        name: 'Outcome',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '5%' },
      },
    ],
  },
  {
    id: 'stay-go',
    title: 'Stay or Go',
    image: 'layout-pyramid.svg',
    type: 'love',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Current State',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Why Stay',
        reversed: void 0,
        position: { x: 'calc(40%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Why Go',
        reversed: void 0,
        position: { x: 'calc(60%)', y: '50%' },
      },
      {
        card_id: null,
        name: "How I'll feel staying",
        reversed: void 0,
        position: { x: 'calc(30%)', y: '90%' },
      },
      {
        card_id: null,
        name: "How I'll feel leaving",
        reversed: void 0,
        position: { x: 'calc(70%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Overall Advice',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '90%' },
      },
    ],
  },
  {
    id: 'maslow',
    title: "Maslow's Hierarchy of Needs",
    image: 'layout-arrow.svg',
    type: 'spiritual',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Physiological',
        reversed: void 0,
        position: { x: 'calc(30%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Safety',
        reversed: void 0,
        position: { x: 'calc(70%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Love & Belonging',
        reversed: void 0,
        position: { x: 'calc(60%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Esteem',
        reversed: void 0,
        position: { x: 'calc(40%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Self-Actualization',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
    ],
  },
  {
    id: 'self-love',
    title: 'Self Love',
    image: 'layout-rays.svg',
    type: 'spiritual',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Quality',
        reversed: void 0,
        position: {
          x: '10%',
          y: '30%',
          rotate: 'rotate(-10deg)',
        },
      },
      {
        card_id: null,
        name: 'Accomplishment',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Radiance',
        reversed: void 0,
        position: {
          x: 'calc(80%)',
          y: '30%',
          rotate: 'rotate(10deg)',
        },
      },
      {
        card_id: null,
        name: 'Skill',
        reversed: void 0,
        position: {
          x: 'calc(80%)',
          y: '70%',
          rotate: 'rotate(-10deg)',
        },
      },
      {
        card_id: null,
        name: 'Shadow',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Expression',
        reversed: void 0,
        position: {
          x: '10%',
          y: '70%',
          rotate: 'rotate(10deg)',
        },
      },
    ],
  },
  {
    id: 'archetypes',
    title: 'Jungian Archetypes',
    image: 'layout-corner-center.svg',
    type: 'spiritual',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Persona',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '20%' },
      },
      {
        card_id: null,
        name: 'Shadow',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '20%' },
      },
      {
        card_id: null,
        name: 'Anima',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '80%' },
      },
      {
        card_id: null,
        name: 'Animus',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '80%' },
      },
      {
        card_id: null,
        name: 'Self',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
    ],
  },
  {
    id: 'dream-mirror',
    title: 'Dream Mirror',
    image: 'layout-corner-cross.svg',
    type: 'spiritual',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Waking Life Environment',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Dream World Environment',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Waking Emotions',
        reversed: void 0,
        position: { x: '50%', y: '30%' },
      },
      {
        card_id: null,
        name: 'Dream Emotions',
        reversed: void 0,
        position: { x: '50%', y: '70%' },
      },
      {
        card_id: null,
        name: 'Foundations',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Lesson',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Waking Life Block',
        reversed: void 0,
        position: {
          x: '73%',
          y: '70%',
          rotate: 'rotate(90deg)',
        },
      },
    ],
  },
  {
    id: 'self-growth',
    title: 'Self Growth',
    image: 'layout-grid-cross.svg',
    type: 'spiritual',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Past Self',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Current Self',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Highest Self',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Blocks Potential',
        reversed: void 0,
        position: {
          x: '44%',
          y: '50%',
          rotate: 'rotate(90deg)',
        },
      },
      {
        card_id: null,
        name: 'Family Patterns',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Personal Issues',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Conscience',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Desire',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Lesson',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Advice',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '90%' },
      },
    ],
  },
  {
    id: 'new-moon',
    title: 'New Moon',
    image: 'layout-3-horizontal.svg',
    type: 'moon',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Where you want to be',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Where you are',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Where you have been',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '50%' },
      },
    ],
  },
  {
    id: 'wax-cres',
    title: 'Waxing Crescent',
    image: 'layout-pyramid.svg',
    type: 'moon',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Your Talents',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '100%' },
      },
      {
        card_id: null,
        name: 'Your Environment',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '100%' },
      },
      {
        card_id: null,
        name: 'Your Resources',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '100%' },
      },
      {
        card_id: null,
        name: 'What Cannot Change',
        reversed: void 0,
        position: { x: 'calc(35%)', y: '60%' },
      },
      {
        card_id: null,
        name: 'What Must Change',
        reversed: void 0,
        position: { x: 'calc(65%)', y: '60%' },
      },
      {
        card_id: null,
        name: 'Outcome',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '20%' },
      },
    ],
  },
  {
    id: 'first-quarter',
    title: 'First Quarter',
    image: 'layout-reverse-pyramid.svg',
    type: 'moon',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'The Querent',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '100%' },
      },
      {
        card_id: null,
        name: 'Expected Obstacle',
        reversed: void 0,
        position: { x: 'calc(40%)', y: '60%' },
      },
      {
        card_id: null,
        name: 'Overcomes Expected Obstacle',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '20%' },
      },
      {
        card_id: null,
        name: 'Strengthens Expected Obstacle',
        reversed: void 0,
        position: { x: 'calc(40%)', y: '20%' },
      },
      {
        card_id: null,
        name: 'Unforeseen Obstacle',
        reversed: void 0,
        position: { x: 'calc(60%)', y: '60%' },
      },
      {
        card_id: null,
        name: 'Overcomes Unforeseen Obstacle',
        reversed: void 0,
        position: { x: 'calc(60%)', y: '20%' },
      },
      {
        card_id: null,
        name: 'Strengthens Unforeseen Obstacle',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '20%' },
      },
    ],
  },
  {
    id: 'wax-gibb',
    title: 'Waxing Gibbous',
    image: 'layout-cross.svg',
    type: 'moon',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'The Problem',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'What You Expected',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'What You Got',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Your Weakness',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Your Strength',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '50%' },
      },
    ],
  },
  {
    id: 'full-moon',
    title: 'Full Moon',
    image: 'layout-arch.svg',
    type: 'moon',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Self Perception',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '80%' },
      },
      {
        card_id: null,
        name: 'Best Qualities',
        reversed: void 0,
        position: {
          x: 'calc(20%)',
          y: '33%',
          rotate: 'rotate(-10deg)',
        },
      },
      {
        card_id: null,
        name: 'Your Gift to Others',
        reversed: void 0,
        position: {
          x: 'calc(40%)',
          y: '27%',
          rotate: 'rotate(-5deg)',
        },
      },
      {
        card_id: null,
        name: 'What Fulfills You',
        reversed: void 0,
        position: {
          x: 'calc(60%)',
          y: '27%',
          rotate: 'rotate(5deg)',
        },
      },
      {
        card_id: null,
        name: 'Unrecognized Potential',
        reversed: void 0,
        position: {
          x: 'calc(80%)',
          y: '33%',
          rotate: 'rotate(10deg)',
        },
      },
    ],
  },
  {
    id: 'wane-gibb',
    title: 'Waning Gibbous',
    image: 'layout-line.svg',
    type: 'moon',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Full Potential',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Fears',
        reversed: void 0,
        position: { x: 'calc(40%)', y: '40%' },
      },
      {
        card_id: null,
        name: 'Hidden Self',
        reversed: void 0,
        position: { x: 'calc(60%)', y: '40%' },
      },
      {
        card_id: null,
        name: 'Deception',
        reversed: void 0,
        position: { x: 'calc(40%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Regret',
        reversed: void 0,
        position: { x: 'calc(60%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Rejected Self',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '100%' },
      },
    ],
  },
  {
    id: 'last-quarter',
    title: 'Last Quarter',
    image: 'layout-corners.svg',
    type: 'moon',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Acceptance',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Forgiveness',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Guidance',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Lesson',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '90%' },
      },
    ],
  },
  {
    id: 'wane-cres',
    title: 'Waning Crescent',
    image: 'layout-single.svg',
    type: 'moon',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Cycle Learnings',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
    ],
  },
  {
    id: 'major0',
    title: 'The Fool',
    image: 'layout-pyramid.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Adventure',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Position',
        reversed: void 0,
        position: { x: 'calc(35%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Risk',
        reversed: void 0,
        position: { x: 'calc(65%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Warning',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Foundation',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Helping Hand',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '90%' },
      },
    ],
  },
  {
    id: 'major1',
    title: 'The Magician',
    image: 'layout-magician.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Inspiration',
        reversed: void 0,
        position: { x: 'calc(40%)', y: '15%' },
      },
      {
        card_id: null,
        name: 'Querent',
        reversed: void 0,
        position: { x: 'calc(60%)', y: '15%' },
      },
      {
        card_id: null,
        name: 'Wands',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Cups',
        reversed: void 0,
        position: { x: 'calc(40%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Swords',
        reversed: void 0,
        position: { x: 'calc(60%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Pentacles',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Manifestation',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '85%' },
      },
    ],
  },
  {
    id: 'major2',
    title: 'The High Priestess',
    image: 'layout-staggered-box.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Mystery',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Avoidance',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '20%' },
      },
      {
        card_id: null,
        name: 'Unconscious Fear',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Conscious Desire',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Work',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '20%' },
      },
      {
        card_id: null,
        name: 'Higher Wisdom',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '60%' },
      },
    ],
  },
  {
    id: 'major3',
    title: 'The Empress',
    image: 'layout-t.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Give',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Keep',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Nurture',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Pull Back',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '80%' },
      },
    ],
  },
  {
    id: 'major4',
    title: 'The Emperor',
    image: 'layout-emperor.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Disorder',
        reversed: void 0,
        position: { x: 'calc(60%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'My Domain',
        reversed: void 0,
        position: { x: 'calc(40%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Outside Domain',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'To Discard',
        reversed: void 0,
        position: { x: 'calc(60%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'To Maintain',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'To Protect',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '70%' },
      },
    ],
  },
  {
    id: 'major5',
    title: 'The Hierophant',
    image: 'layout-arrow-down.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Message',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '15%' },
      },
      {
        card_id: null,
        name: 'Querent',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Earthly Action',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '85%' },
      },
      {
        card_id: null,
        name: 'Belonging',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '65%' },
      },
      {
        card_id: null,
        name: 'Guidance',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '65%' },
      },
    ],
  },
  {
    id: 'major6',
    title: 'The Lovers',
    image: 'layout-lovers.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Underlying Issues',
        reversed: void 0,
        position: { x: 'calc(40%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Work',
        reversed: void 0,
        position: { x: 'calc(60%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Self Love',
        reversed: void 0,
        position: {
          x: 'calc(15%)',
          y: '30%',
          rotate: 'rotate(-20deg)',
        },
      },
      {
        card_id: null,
        name: 'Affection',
        reversed: void 0,
        position: {
          x: 'calc(35%)',
          y: '10%',
          rotate: 'rotate(-10deg)',
        },
      },
      {
        card_id: null,
        name: 'Familiar Love',
        reversed: void 0,
        position: {
          x: 'calc(65%)',
          y: '10%',
          rotate: 'rotate(10deg)',
        },
      },
      {
        card_id: null,
        name: 'Romantic Love',
        reversed: void 0,
        position: {
          x: 'calc(85%)',
          y: '30%',
          rotate: 'rotate(20deg)',
        },
      },
      {
        card_id: null,
        name: 'Enduring Love',
        reversed: void 0,
        position: {
          x: 'calc(85%)',
          y: '70%',
          rotate: 'rotate(-20deg)',
        },
      },
      {
        card_id: null,
        name: 'Playful Love',
        reversed: void 0,
        position: {
          x: 'calc(65%)',
          y: '90%',
          rotate: 'rotate(-10deg)',
        },
      },
      {
        card_id: null,
        name: 'Obsessive Love',
        reversed: void 0,
        position: {
          x: 'calc(35%)',
          y: '90%',
          rotate: 'rotate(10deg)',
        },
      },
      {
        card_id: null,
        name: 'Selfless Love',
        reversed: void 0,
        position: {
          x: 'calc(15%)',
          y: '70%',
          rotate: 'rotate(20deg)',
        },
      },
    ],
  },
  {
    id: 'major7',
    title: 'The Chariot',
    image: 'layout-chariot.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Charioteer',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Dark Horse',
        reversed: void 0,
        position: { x: 'calc(25%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Light Horse',
        reversed: void 0,
        position: { x: 'calc(75%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Next Step',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '42.5%' },
      },
      {
        card_id: null,
        name: 'Obstacle',
        reversed: void 0,
        position: {
          x: 'calc(50%)',
          y: '68.5%',
          rotate: 'rotate(90deg)',
        },
      },
      {
        card_id: null,
        name: 'Destination',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '95%' },
      },
    ],
  },
  {
    id: 'major8',
    title: 'Strength',
    image: 'layout-strength.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Self Perception',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Challenge',
        reversed: void 0,
        position: {
          x: 'calc(50%)',
          y: '40%',
          rotate: 'rotate(90deg)',
        },
      },
      {
        card_id: null,
        name: 'Skill',
        reversed: void 0,
        position: {
          x: 'calc(20%)',
          y: '40%',
          rotate: 'rotate(-10deg)',
        },
      },
      {
        card_id: null,
        name: 'Blessings',
        reversed: void 0,
        position: {
          x: 'calc(80%)',
          y: '40%',
          rotate: 'rotate(10deg)',
        },
      },
      {
        card_id: null,
        name: 'Wisdom',
        reversed: void 0,
        position: {
          x: 'calc(20%)',
          y: '80%',
          rotate: 'rotate(10deg)',
        },
      },
      {
        card_id: null,
        name: 'Potential',
        reversed: void 0,
        position: {
          x: 'calc(80%)',
          y: '80%',
          rotate: 'rotate(-10deg)',
        },
      },
    ],
  },
  {
    id: 'major9',
    title: 'The Hermit',
    image: 'layout-reverse-t.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Dark',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '40%' },
      },
      {
        card_id: null,
        name: 'Persona',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '80%' },
      },
      {
        card_id: null,
        name: 'True Self',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '80%' },
      },
      {
        card_id: null,
        name: 'Guiding Light',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '80%' },
      },
    ],
  },
  {
    id: 'major10',
    title: 'The Wheel of Fortune',
    image: 'layout-wheel.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Self',
        reversed: void 0,
        position: { x: 'calc(40%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Environment',
        reversed: void 0,
        position: { x: 'calc(60%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Winter',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Spring',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Summer',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Fall',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '50%' },
      },
    ],
  },
  {
    id: 'major11',
    title: 'Justice',
    image: 'layout-justice.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Your Needs',
        reversed: void 0,
        position: { x: 'calc(30%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Their Needs',
        reversed: void 0,
        position: { x: 'calc(70%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'My Path - Rewards',
        reversed: void 0,
        position: { x: 'calc(10%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'My Path - Consequences',
        reversed: void 0,
        position: { x: 'calc(10%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Their Path - Rewards',
        reversed: void 0,
        position: { x: 'calc(90%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Their Path - Consequences',
        reversed: void 0,
        position: { x: 'calc(90%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Balance',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Advice: Act',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Advice: Retreat',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '90%' },
      },
    ],
  },
  {
    id: 'major12',
    title: 'The Hanged Man',
    image: 'layout-hanged.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Challenge',
        reversed: void 0,
        position: {
          x: 'calc(50%)',
          y: '20%',
          rotate: 'rotate(90deg)',
        },
      },
      {
        card_id: null,
        name: 'Feelings',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '20%' },
      },
      {
        card_id: null,
        name: 'Origins',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '20%' },
      },
      {
        card_id: null,
        name: 'Sacrifice',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Awakening',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '90%' },
      },
    ],
  },
  {
    id: 'major13',
    title: 'Death',
    image: 'layout-trapezoid.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Death',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Before',
        reversed: void 0,
        position: { x: 'calc(30%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'After',
        reversed: void 0,
        position: { x: 'calc(70%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'To Grieve',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'To Remember',
        reversed: void 0,
        position: { x: 'calc(40%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'To Release',
        reversed: void 0,
        position: { x: 'calc(60%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'To Heal',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '70%' },
      },
    ],
  },
  {
    id: 'major14',
    title: 'Temperance',
    image: 'layout-temperance.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Prima Materia 1',
        reversed: void 0,
        position: { x: 'calc(25%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Prima Materia 2',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Prima Materia 3',
        reversed: void 0,
        position: { x: 'calc(35%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Decomposition',
        reversed: void 0,
        position: { x: 'calc(65%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Purification',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Awakening',
        reversed: void 0,
        position: { x: 'calc(65%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Culmination',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '70%' },
      },
    ],
  },
  {
    id: 'major15',
    title: 'Devil',
    image: 'layout-8.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Ego',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Shadow',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Comfort',
        reversed: void 0,
        position: { x: 'calc(37.5%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Avoidance',
        reversed: void 0,
        position: { x: 'calc(37.5%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Projection',
        reversed: void 0,
        position: { x: 'calc(62.5%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Rejection',
        reversed: void 0,
        position: { x: 'calc(62.5%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Forgiveness',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Release',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '70%' },
      },
    ],
  },
  {
    id: 'major16',
    title: 'Tower',
    image: 'layout-tower.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Rage',
        reversed: void 0,
        position: {
          x: 'calc(20%)',
          y: '10%',
          rotate: 'rotate(-20deg)',
        },
      },
      {
        card_id: null,
        name: 'Break 1',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '20%' },
      },
      {
        card_id: null,
        name: 'Break 2',
        reversed: void 0,
        position: { x: 'calc(40%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Break 3',
        reversed: void 0,
        position: { x: 'calc(60%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Void',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'To Save',
        reversed: void 0,
        position: { x: 'calc(30%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'To Uncover',
        reversed: void 0,
        position: { x: 'calc(70%)', y: '90%' },
      },
    ],
  },
  {
    id: 'major17',
    title: 'Star',
    image: 'layout-star.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Wound',
        reversed: void 0,
        position: { x: 'calc(30%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Guidance',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Grounding',
        reversed: void 0,
        position: { x: 'calc(70%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Inspiration',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Blessing',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Wish',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
    ],
  },
  {
    id: 'major18',
    title: 'Moon',
    image: 'layout-moon.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Darkness',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Moon',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '20%' },
      },
      {
        card_id: null,
        name: 'Reflection',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '60%' },
      },
      {
        card_id: null,
        name: 'Dog',
        reversed: void 0,
        position: { x: 'calc(25%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Wolf',
        reversed: void 0,
        position: { x: 'calc(75%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Path',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '90%' },
      },
    ],
  },
  {
    id: 'major19',
    title: 'Sun',
    image: 'layout-sun.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Abundance',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Sunlight',
        reversed: void 0,
        position: { x: 'calc(35%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Inner Light',
        reversed: void 0,
        position: { x: 'calc(35%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Outer Radiance',
        reversed: void 0,
        position: { x: 'calc(55%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Cloudiness',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '50%' },
      },
    ],
  },
  {
    id: 'major20',
    title: 'Judgement',
    image: 'layout-judgement.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Past Mistakes',
        reversed: void 0,
        position: { x: 'calc(10%)', y: '30%' },
      },
      {
        card_id: null,
        name: 'Past Successes',
        reversed: void 0,
        position: { x: 'calc(10%)', y: '70%' },
      },
      {
        card_id: null,
        name: 'Judgement',
        reversed: void 0,
        position: { x: 'calc(30%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'The Call',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Rebirth',
        reversed: void 0,
        position: { x: 'calc(70%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Result',
        reversed: void 0,
        position: { x: 'calc(90%)', y: '50%' },
      },
    ],
  },
  {
    id: 'major21',
    title: 'World',
    image: 'layout-corner-center.svg',
    type: 'major',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Self Fulfillment',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: 'Taurus: Security',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Leo: Pleasure',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '90%' },
      },
      {
        card_id: null,
        name: 'Scorpio: Passion',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Aquarius: Altruism',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '10%' },
      },
    ],
  },
  {
    id: 'zodiac-inner',
    title: 'Zodiac Spread - Inner World',
    image: 'layout-zodiac.svg',
    type: 'spiritual',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Aries',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '80%' },
      },
      {
        card_id: null,
        name: 'Taurus',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '115%' },
      },
      {
        card_id: null,
        name: 'Gemini',
        reversed: void 0,
        position: { x: 'calc(37.5%)', y: '115%' },
      },
      {
        card_id: null,
        name: 'Cancer',
        reversed: void 0,
        position: { x: 'calc(62.5%)', y: '115%' },
      },
      {
        card_id: null,
        name: 'Leo',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '115%' },
      },
      {
        card_id: null,
        name: 'Virgo',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '80%' },
      },
      {
        card_id: null,
        name: 'Libra',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '45%' },
      },
      {
        card_id: null,
        name: 'Scorpio',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Sagittarius',
        reversed: void 0,
        position: { x: 'calc(62.5%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Capricorn',
        reversed: void 0,
        position: { x: 'calc(37.5%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Aquarius',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Pisces',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '45%' },
      },
    ],
  },
  {
    id: 'zodiac-outer',
    title: 'Zodiac Spread - Outer World',
    image: 'layout-zodiac.svg',
    type: 'general',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Aries',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '80%' },
      },
      {
        card_id: null,
        name: 'Taurus',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '115%' },
      },
      {
        card_id: null,
        name: 'Gemini',
        reversed: void 0,
        position: { x: 'calc(37.5%)', y: '115%' },
      },
      {
        card_id: null,
        name: 'Cancer',
        reversed: void 0,
        position: { x: 'calc(62.5%)', y: '115%' },
      },
      {
        card_id: null,
        name: 'Leo',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '115%' },
      },
      {
        card_id: null,
        name: 'Virgo',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '80%' },
      },
      {
        card_id: null,
        name: 'Libra',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '45%' },
      },
      {
        card_id: null,
        name: 'Scorpio',
        reversed: void 0,
        position: { x: 'calc(85%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Sagittarius',
        reversed: void 0,
        position: { x: 'calc(62.5%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Capricorn',
        reversed: void 0,
        position: { x: 'calc(37.5%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Aquarius',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Pisces',
        reversed: void 0,
        position: { x: 'calc(15%)', y: '45%' },
      },
    ],
  },
  {
    id: 'weekly',
    title: 'Week Ahead',
    image: 'layout-pentagram.svg',
    type: 'general',
    question: null,
    notes: null,
    date: null,
    cards: [
      {
        card_id: null,
        name: 'Theme',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '10%' },
      },
      {
        card_id: null,
        name: 'Challenges',
        reversed: void 0,
        position: { x: 'calc(20%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Blessings',
        reversed: void 0,
        position: { x: 'calc(50%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Self-Care',
        reversed: void 0,
        position: { x: 'calc(80%)', y: '55%' },
      },
      {
        card_id: null,
        name: 'Release',
        reversed: void 0,
        position: { x: 'calc(35%)', y: '100%' },
      },
      {
        card_id: null,
        name: 'Focus',
        reversed: void 0,
        position: { x: 'calc(65%)', y: '100%' },
      },
    ],
  },
  {
    id: 'pair',
    title: 'Pair Reading',
    type: 'lenormand',
    image: 'layout-2-horizontal.svg',
    interpretation: null,
    cards: [
      {
        card_id: null,
        name: '',
        position: { x: 'calc(40%)', y: '50%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(60%)', y: '50%' },
      },
    ],
  },
  {
    id: 'three',
    title: '3 Card Reading',
    type: 'lenormand',
    image: 'layout-3-horizontal.svg',
    interpretation: null,
    cards: [
      {
        card_id: null,
        name: '',
        position: { x: 'calc(20%)', y: '50%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(80%)', y: '50%' },
      },
    ],
  },
  {
    id: 'five',
    title: '5 Card Reading',
    type: 'lenormand',
    image: 'layout-5-horizontal.svg',
    interpretation: null,
    cards: [
      {
        card_id: null,
        name: '',
        position: { x: 'calc(15%)', y: '50%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(32.5%)', y: '50%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(50%)', y: '50%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(67.5%)', y: '50%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(85%)', y: '50%' },
      },
    ],
  },
  {
    id: 'nine',
    title: '9 Card Reading',
    type: 'lenormand',
    image: 'layout-9-box.svg',
    interpretation: null,
    sections: [
      { cards: [4] },
      { cards: [0, 2, 6, 8] },
      { cards: [0, 3, 6] },
      { cards: [1, 4, 7] },
      { cards: [2, 5, 8] },
      { cards: [0, 1, 2] },
      { cards: [3, 4, 5] },
      { cards: [6, 7, 8] },
    ],
    cards: [
      {
        card_id: null,
        name: '',
        position: { x: 'calc(20%)', y: '20%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(50%)', y: '20%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(80%)', y: '20%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(20%)', y: '55%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(50%)', y: '55%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(80%)', y: '55%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(20%)', y: '90%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(50%)', y: '90%' },
      },
      {
        card_id: null,
        name: '',
        position: { x: 'calc(80%)', y: '90%' },
      },
    ],
  },
  {
    id: 'astro',
    title: 'Astrology Oracle Reading',
    type: 'astro',
    image: 'layout-4.svg',
    interpretation: null,
    cards: [
      {
        card_id: null,
        name: '',
        reversed: void 0,
        position: { x: 'calc(30%)', y: '30%' },
      },
      {
        card_id: null,
        name: '',
        reversed: void 0,
        position: { x: 'calc(70%)', y: '30%' },
      },
      {
        card_id: null,
        name: '',
        reversed: void 0,
        position: { x: 'calc(30%)', y: '70%' },
      },
      {
        card_id: null,
        name: '',
        reversed: void 0,
        position: { x: 'calc(70%)', y: '70%' },
      },
    ],
  },
];
