import Modal from 'components/Modal';
import ShareImage from 'pages/ShareImage/ShareImage';
import React, { createContext, useState, useContext } from 'react';

// Context
const ShareImageContext = createContext();

export const ShareImageContextProvider = ({ children }) => {
  const [modalImage, setModalImage] = useState(null);

  const openModal = (imageUrl) => {
    setModalImage(imageUrl);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <ShareImageContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modalImage && (
        <Modal
          onClose={() => {
            closeModal();
          }}
        >
          <ShareImage imageData={modalImage} closeModal={closeModal}></ShareImage>
        </Modal>
      )}
    </ShareImageContext.Provider>
  );
};

// Hook to use the context
export const useShareImageContext = () => {
  return useContext(ShareImageContext);
};
