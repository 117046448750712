import React, { createContext, useState, useEffect } from 'react'

// Create the context
export const AuthContext = createContext()

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  // Check if the user is logged in from localStorage on component mount
  useEffect(() => {
    const storedUser = localStorage.getItem('user')
    console.log(storedUser)
    if (storedUser) {
      setUser(JSON.parse(storedUser))
    }
  }, [])

  // Log in the user and store their data in localStorage
  const loginUser = (userData) => {
    setUser(userData)
    localStorage.setItem('user', JSON.stringify(userData)) // Store user info in localStorage
  }

  // Check if the user is logged in
  const isLoggedIn = user !== null

  // Log out the user
  const logoutUser = () => {
    setUser(null)
    localStorage.removeItem('user') // Remove from localStorage
  }

  return (
    <AuthContext.Provider value={{ user, loginUser, logoutUser, isLoggedIn }}>
      {children}
    </AuthContext.Provider>
  )
}
