import React, { useState, useEffect } from 'react';
import TarotDetailPageForSpread from './TarotDetailPageForSpread';
import Modal from 'components/Modal';

import styled from 'styled-components';

const StyledTarotCardDetail = styled.div``;

export default function TarotCardDetail({
  selectedCard,
  currentSpread,
  clickedIndex,
  spread,
  handleClose,
}) {
  const [showMore, setShowMore] = useState(null);

  return (
    <StyledTarotCardDetail>
      {showMore && (
        <Modal
          onClose={() => {
            setShowMore(null);
          }}
        >
          <TarotDetailPageForSpread
            headline={
              currentSpread.cards[clickedIndex].reversed
                ? selectedCard.titleReversed
                : selectedCard.title
            }
            selectedCard={selectedCard}
            reversed={currentSpread.cards[clickedIndex].reversed}
            position={clickedIndex + 1}
            spreadTitle={currentSpread.title}
            spreadQuestion={currentSpread.question}
            spreadTopic={currentSpread.cards[clickedIndex].name}
            spreadTopicQuestion={t(
              `spreads.spreads.${spread.id}.cards.${clickedIndex}.description`,
              { lng: 'en' },
            )}
            handleClose={() => setShowMore(null)}
          ></TarotDetailPageForSpread>
        </Modal>
      )}
    </StyledTarotCardDetail>
  );
}
