import { BASE_URL, HOROSCOPE_API, HOROSCOPE_EXPLANATIONS_API } from 'constants/URLS';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'context/APIcontext';
import styled from 'styled-components';
import { getLanguage } from 'helper/getLanguage';
import LoadingState from 'components/LoadingState';
import * as animationData from 'assets/lottie/swipe-left.json';
import Lottie from 'react-lottie';
import ShareOutlineIcon from 'components/SVG/ShareOutlineIcon';
import BookOutlineIcon from 'components/SVG/BookOutlineIcon';
import CloseIcon from 'components/SVG/CloseIcon';
import screenshotShare from 'helper/screenshotShare';
import Modal from 'components/Modal';
import HoroscopeDetailFull from './HoroscopeDetailFull';
import useSwipeDetection from 'hooks/useSwipeDetection';
import BookIcon from 'components/SVG/BookIcon';
import ShareIcon from 'components/SVG/ShareIcon';
import { useShareImageContext } from 'context/ShareContext';

const StyledHoroscopePage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: 580px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  .affirmation-category {
    position: absolute;
    top: 12px;
    text-transform: uppercase;
  }

  .affirmation-text {
    color: rgb(46, 59, 75);
    font-family: now, sans-serif;
    font-size: 16px;
    text-align: center;
    line-height: 1.4;
    margin: 0px 5% 10px;
    width: 90%;
    max-width: 740px;
    min-height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: 375px) {
      font-size: 14px;
    }

    .headline {
      font-family: 'clever-german', serif;
      font-size: 18px;
      line-height: 1.2;
      font-weight: 700 !important;
      color: #111;
      color: #c19c7b;
      margin: 0 0 10px;
    }

    b {
      color: #c19c7b;
      font-family: 'breathing', sans-serif;
      font-size: 30px;
      line-height: 1.2;
      position: relative;
      top: 10px;
      left: 4px;
      margin-bottom: 20px;
      display: block;

      @media only screen and (max-width: 375px) {
        font-size: 24px;
      }
    }

    h6 {
      font-size: 12px;
    }

    .tell-me-more {
      margin-top: 20px;
      background-color: #fff;
      border-radius: 10px;
      font-size: 14px;
      display: inline-block;
      padding: 10px 20px;
      color: #7f8aa5;
      border-left: 1px solid #eee;
    }
  }

  .affirmation-button-wrapper {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 9999;
    padding-bottom: 40px;

    .affirmation-button-save {
      padding: 6px 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      ion-icon {
        font-size: 16px;
        padding-right: 10px;
        color: #7f8aa5;
      }
    }

    .affirmation-button-share {
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 10px;
      border-radius: 10px;
      margin-top: 10px;

      ion-icon {
        font-size: 16px;
        padding-right: 10px;
        color: #7f8aa5;
      }

      span {
        color: #7f8aa5;
        font-size: 14px;
        display: block;
        border-left: 1px solid #eee;
        padding-left: 10px;
      }
    }
  }

  .steps-blocks {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 0 0;
    position: absolute;
    width: 100%;
    top: 0;
    max-width: 740px;

    .step-block {
      width: 100%;
      height: 8px;
      overflow: hidden;
      //box-shadow: #cccccc 0 0 16px;
      background: linear-gradient(to left, #fff 50%, #c19c7b 50%) right;
      background-size: 201%;
      transition: 0.5s ease-out;

      .step-progress {
      }

      &.is-done {
        background-position: left;
        .step-progress {
        }
      }
    }
  }

  .article-detail-image {
    position: relative;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    min-height: 430px;
  }

  .page-title,
  .page-title-small {
    text-align: center;
    max-width: 100%;
  }

  .page-title-big {
    line-height: 1.2 !important;
    font-size: 26px;
  }

  .page-content {
    margin-top: -180px;
    position: relative;
    z-index: 9;

    .page-content-text {
      margin: 0 3% 20px;

      h4 {
        font-family: 'clever-german', serif;
        line-height: 1.4;
        font-size: 22px;
        border-top: 2px solid #eee;
        padding-top: 40px;
        margin-top: 40px;
        text-align: center;
      }

      h5 {
        font-family: 'clever-german', serif;
        line-height: 1.4;
        font-size: 18px;
        text-decoration: underline;
        text-align: center;
      }

      h6 {
        font-size: 14px;
        text-align: center;
      }

      p {
        font-size: 15px;
        line-height: 1.6;
      }

      ul {
        padding-left: 0;
        margin: 0;
        list-style: none;
        font-size: 15px;

        li {
          position: relative;
          padding-left: 10px;
          margin-bottom: 10px;
          line-height: 1.6;

          &:before {
            content: '•';
            position: absolute;
            left: 0;
            color: #111;
          }
        }
      }
    }
  }

  .swipe-animation {
    position: fixed;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
  }
`;

export default function HoroscopeDetail({ day, month, year, selectedUserData, timeframe, goBack }) {
  const { t } = useTranslation('common');

  const { openModal } = useShareImageContext();

  const [isLoading, setIsLoading] = useState(true);
  const [fullHoroscope, setFullHoroscope] = useState([]);
  const [currentStory, setCurrentStory] = useState(null);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [currentStoryAnimate, setCurrentStoryAnimate] = useState(false);
  const [selectedTranstitDetail, setSelectedTranstitDetail] = useState(null);
  const [mode, setMode] = useState();
  const [showSwipeAnimation, setShowSwipeAnimation] = useState(true);
  const [currentTransits, setCurrentTransits] = useState([]);
  const [longerTransits, setLongerTransits] = useState([]);
  const [selectedHoroscopeForView, setSelectedHoroscopeForView] = useState([]);
  const [initialTouchX, setInitialTouchX] = useState(null);
  const [showFull, setShowFull] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!mode) {
        setMode('story');

        const horoscope = await getHoroscope(selectedUserData);

        const explanations = await getHoroscopeExplanations(horoscope.map((entry) => entry.title));

        const horoscopeWithExplanations = horoscope.map((entry) => {
          const explanation = explanations.find((ex) => ex.key === entry.title);
          entry.explanation = explanation;
          return entry;
        });

        const currentTransits = horoscopeWithExplanations;

        setCurrentTransits(currentTransits.sort((a, b) => b.time - a.time));

        setFullHoroscope(horoscopeWithExplanations.sort((a, b) => a.time - b.time));
        setSelectedHoroscopeForView(currentTransits);
        setCurrentStory(currentTransits[0]);
        setSelectedTranstitDetail(currentTransits[0]);
        setCurrentStoryAnimate(true);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const onSwipeLeft = () => {
    setShowSwipeAnimation(false);
    if (currentStoryIndex < fullHoroscope.length - 1) {
      setCurrentStoryAnimate(false);
      setCurrentStoryIndex(currentStoryIndex + 1);
      setCurrentStory(fullHoroscope[currentStoryIndex + 1]);
      setSelectedTranstitDetail(fullHoroscope[currentStoryIndex + 1]);
      setTimeout(() => {
        setCurrentStoryAnimate(true);
      }, 100);
    }
  };

  const onSwipeRight = () => {
    setShowSwipeAnimation(false);
    if (currentStoryIndex > 0) {
      setCurrentStoryAnimate(false);
      setCurrentStoryIndex(currentStoryIndex - 1);
      setCurrentStory(fullHoroscope[currentStoryIndex - 1]);
      setSelectedTranstitDetail(fullHoroscope[currentStoryIndex - 1]);
      setTimeout(() => {
        setCurrentStoryAnimate(true);
      }, 100);
    }
  };

  const takeAndShareScreenshot = async () => {
    const imageData = await screenshotShare();
    openModal(imageData);
  };

  const { handleStart, handleEnd } = useSwipeDetection(onSwipeLeft, onSwipeRight);

  const selectView = (view) => {
    setView(view);
    if (view === 'current') {
      setSelectedHoroscopeForView(currentTransits);
    }

    if (view === 'long') {
      setSelectedHoroscopeForView(longerTransits);
    }
  };

  const getHoroscope = async (userData) => {
    const birthChartAPI = `${HOROSCOPE_API}?language=${getLanguage()}&birthDay=${
      userData.birthDay
    }&birthMonth=${userData.birthMonth}&birthYear=${userData.birthYear}&birthHour=${
      userData.birthHour
    }&birthMinute=${userData.birthMinute}&latitude_degrees=${
      userData.location.latitude_degrees
    }&latitude_direction=${userData.location.latitude_direction}&latitude_minutes=${
      userData.location.latitude_minutes
    }&longitude_degrees=${userData.location.longitude_degrees}&longitude_direction=${
      userData.location.longitude_direction
    }&longitude_minutes=${userData.location.longitude_minutes}&city=${
      userData.location.city
    }&country_short=${userData.location.country_short}&region=${
      userData.location.region
    }&day=${day}&month=${month}&year=${year}`;

    const response = await fetch(birthChartAPI);
    let data = await response.json();
    if (timeframe === 'today') {
      data = data.filter((entry) => entry.time <= 1);
    }
    if (timeframe === 'week') {
      data = data.filter((entry) => entry.time > 1 && entry.time <= 30);
    }
    if (timeframe === 'month') {
      data = data.filter((entry) => entry.time > 1);
    }
    return data;
  };

  const getHoroscopeExplanations = async (titles) => {
    try {
      const language = getLanguage();
      const requests = titles.map(async (title) => {
        const brithChartAPI = `${HOROSCOPE_EXPLANATIONS_API}?titles[]=${encodeURIComponent(
          title,
        )}&language=${language}`;
        const response = await fetch(brithChartAPI);
        return response.json();
      });
      const responses = await Promise.all(requests);
      const mergedArray = responses.reduce((acc, val) => acc.concat(val), []);
      return mergedArray;
    } catch (error) {
      console.error('Error fetching horoscope explanations:', error);
    }
  };

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  return (
    <>
      {isLoading ? (
        <LoadingState
          title={t('article-page.transit-loading-headline')}
          text={t('article-page.transit-loading-text')}
        />
      ) : (
        <StyledHoroscopePage onTouchStart={handleStart} onTouchEnd={handleEnd}>
          <>
            <div className="steps-blocks hide-to-share">
              {fullHoroscope.map((block, i) => (
                <div
                  key={i}
                  className={`step-block ${currentStoryIndex >= i ? 'is-done' : ''}`}
                ></div>
              ))}
            </div>

            {currentStoryAnimate && (
              <div className="affirmation-text" onClick={onSwipeLeft}>
                <div
                  className="headline animation animated fadeIn"
                  dangerouslySetInnerHTML={{ __html: currentStory.explanation.transition_title }}
                ></div>

                <b
                  className="animation animated fadeIn"
                  dangerouslySetInnerHTML={{ __html: currentStory.explanation.title }}
                ></b>

                {/*<h6 className="animation animated fadeIn">
                  {currentStory.time > 1 ? (
                    <>
                      <span>{t('article-page.duration')}</span>
                      {currentStory.time}
                      <span>{t('days')}</span>
                    </>
                  ) : (
                    <span>{t('article-page.transit-is-intense')}</span>
                  )}
                  </h6>*/}

                <div
                  className="animation animated fadeIn"
                  dangerouslySetInnerHTML={{ __html: currentStory.explanation.share_text }}
                ></div>

                {showSwipeAnimation && (
                  <div className="swipe-animation hide-to-share">
                    <Lottie
                      options={defaultOptions}
                      height={200}
                      width={200}
                      isStopped={defaultOptions.isStopped}
                      isPaused={defaultOptions.isPaused}
                    />
                  </div>
                )}
              </div>
            )}

            {currentStoryAnimate && (
              <div className="story-button-wrapper hide-to-share animation animated fadeIn">
                <div
                  className="story-button-share"
                  onClick={() => {
                    takeAndShareScreenshot();
                  }}
                >
                  <ShareIcon />
                  <span>{t('share')}</span>
                </div>

                <div
                  className="story-button-share"
                  onClick={() => {
                    setShowFull(true);
                  }}
                >
                  <BookIcon />
                  <span>{t('tell-me-more')}</span>
                </div>

                {/*<div
                  className="story-button-share"
                  onClick={() => {
                    goBack();
                  }}
                >
                  <CloseIcon />
                  <span>{t('close')}</span>
                </div>*/}
              </div>
            )}

            <div className="screenshot-watermark show-to-share">
              <img
                className="screenshot-watermark-logo"
                src={`${BASE_URL}/react/assets/img/tiny-affirmation-logo-share.png`}
              ></img>
              <div className="screenshot-watermark-text">
                <h4>{t('screenshot-watermark-headline')}</h4>
                <span>{t('screenshot-watermark-text')}</span>
              </div>
            </div>
          </>

          {showFull && (
            <Modal
              onClose={() => {
                setShowFull(false);
              }}
            >
              <HoroscopeDetailFull
                selectedTranstitDetail={selectedTranstitDetail}
                goBack={() => setShowFull(false)}
              />
            </Modal>
          )}
        </StyledHoroscopePage>
      )}
    </>
  );
}
