import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import ShareOutlineIcon from 'components/SVG/ShareOutlineIcon'
import BookOutlineIcon from 'components/SVG/BookOutlineIcon'
import CloseIcon from 'components/SVG/CloseIcon'
import screenshotShare from 'helper/screenshotShare'
import Modal from 'components/Modal'
import useSwipeDetection from 'hooks/useSwipeDetection'
import * as animationData from 'assets/lottie/swipe-left.json'
import ShareIcon from 'components/SVG/ShareIcon'
import BookIcon from 'components/SVG/BookIcon'
import { BASE_URL, DATA_FOLDER, TAROT_EXPLANATIONS_API } from 'constants/URLS'
import PlayIcon from 'components/SVG/PlayIcon'
import StopIcon from 'components/SVG/StopIcon'
import CheckmarkIcon from 'components/SVG/CheckmarkIcon'
import { getLanguage } from 'helper/getLanguage'
import LoadingState from 'components/LoadingState'
import ChevronForwardIcon from 'components/SVG/ChevronForwardIcon'
import { useShareImageContext } from 'context/ShareContext'
import { StyledStoryPage } from 'StyledStoryPage'

export default function TarotDetailPage({ selectedCard, reversed, handleClose }) {
  const { t } = useTranslation('common')
  const { openModal } = useShareImageContext()

  const [storys, setStorys] = useState([])
  const [currentStory, setCurrentStory] = useState('')
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0)
  const [currentStoryAnimate, setCurrentStoryAnimate] = useState(true)
  const [showSwipeAnimation, setShowSwipeAnimation] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [headline, setHeadline] = useState('')

  useEffect(() => {
    prepareReading()
  }, [])

  const prepareReading = () => {
    let text = selectedCard.meaningUpright
    setHeadline(selectedCard.title)

    if (reversed) {
      text = selectedCard.meaningReverse
      setHeadline(selectedCard.titleReversed)
    }

    const loadedStories = JSON.parse(text.replaceAll('```json', '').replaceAll('```', ''))

    const newStorys = [
      {
        content: '',
        index: 0,
      },
      ...loadedStories.map((story, index) => ({
        headline: story.headline,
        text: story.text,
        index: index + 1,
      })),
    ]

    setIsLoading(false)
    setStorys(newStorys)
    setCurrentStory(newStorys[currentStoryIndex])
  }

  const onSwipeLeft = () => {
    setShowSwipeAnimation(false)
    if (currentStoryIndex < storys.length - 1) {
      setCurrentStoryAnimate(false)
      setCurrentStoryIndex(currentStoryIndex + 1)
      setCurrentStory(storys[currentStoryIndex + 1])
      setTimeout(() => {
        setCurrentStoryAnimate(true)
      }, 100)
    } else {
      handleClose()
    }
  }

  const onSwipeRight = () => {
    setShowSwipeAnimation(false)
    if (currentStoryIndex > 0) {
      setCurrentStoryAnimate(false)
      setCurrentStoryIndex(currentStoryIndex - 1)
      setCurrentStory(storys[currentStoryIndex - 1])
      setTimeout(() => {
        setCurrentStoryAnimate(true)
      }, 100)
    }
  }

  const takeAndShareScreenshot = async () => {
    const imageData = await screenshotShare()
    openModal(imageData)
  }

  const { handleStart, handleEnd } = useSwipeDetection(onSwipeLeft, onSwipeRight)

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  })

  return (
    <>
      {isLoading ? (
        <LoadingState title={t('spreads.loading-headline')} text={t('spreads.loading-text')} />
      ) : (
        <StyledStoryPage onTouchStart={handleStart} onTouchEnd={handleEnd}>
          <>
            <div className="story-steps-blocks hide-to-share">
              {storys.map((block, i) => (
                <div
                  key={i}
                  className={`step-block ${currentStoryIndex >= i ? 'is-done' : ''}`}
                ></div>
              ))}
            </div>

            {showSwipeAnimation && (
              <div className="story-swipe-animation hide-to-share">
                <Lottie
                  options={defaultOptions}
                  height={200}
                  width={200}
                  isStopped={defaultOptions.isStopped}
                  isPaused={defaultOptions.isPaused}
                />
              </div>
            )}

            {currentStoryAnimate && currentStory.index === 0 && (
              <div className="story-text is-tarot-page">
                <b className="card-title animation animated fadeIn">
                  {reversed ? selectedCard.titleReversed : selectedCard.title}
                </b>

                {/*<div className="card-text animation animated fadeIn">
                    <span>
                      {reversed
                        ? selectedCard.shortReversedDescription
                        : selectedCard.shortNormalDescription}
                    </span>
                  </div>*/}

                <div className="card-lesson animation animated fadeIn">
                  {reversed ? selectedCard.lessonReversed : selectedCard.lesson}
                </div>

                <img
                  src={`${BASE_URL}/api/images/tarotcards/${selectedCard.image}.jpg`}
                  alt={reversed ? selectedCard.titleReversed : selectedCard.title}
                  className="animation animated fadeIn"
                  style={{
                    maxHeight: '30vh',
                    margin: '20px 0 0',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    rotate: reversed ? 'transform: rotate(180deg)' : '',
                  }}
                />

                <div className="card-keywords animation animated fadeIn">
                  <b>{t('tarot-detail.keywords')}</b>
                  {reversed
                    ? selectedCard.keywordReversed
                        .split(',')
                        .map((keyword, index) => <span>{keyword}</span>)
                    : selectedCard.keywords
                        .split(',')
                        .map((keyword, index) => <span>{keyword}</span>)}
                </div>

                {/*<div className="grid animation animated fadeIn">
                  <div className="card-yesOrNo">
                    <b>{t('tarot-detail.yes-or-no')}</b>
                    <span>{reversed ? selectedCard.yesOrNoReversed : selectedCard.yesOrNo}</span>
                  </div>

                  <div className="card-element">
                    <b>{t('tarot-detail.element')}</b>
                    <span>{selectedCard.element}</span>
                  </div>

                  <div className="card-astrologie">
                    <b>{t('tarot-detail.astrologie')}</b>
                    <span>{selectedCard.astrologie}</span>
                  </div>

                  <div className="card-nummerlogie">
                    <b>{t('tarot-detail.nummerlogie')}</b>
                    <span>{selectedCard.nummerlogie}</span>
                  </div>
                  </div>*/}
                {currentStoryAnimate && (
                  <div className="story-button-wrapper hide-to-share animation animated fadeIn">
                    <div
                      className="story-button-share"
                      onClick={() => {
                        takeAndShareScreenshot()
                      }}
                    >
                      <ShareIcon />
                      <span>{t('share')}</span>
                    </div>
                    <div className="story-button-share" onClick={onSwipeLeft}>
                      <ChevronForwardIcon />
                      <span>{t('continue')}</span>
                    </div>
                  </div>
                )}
              </div>
            )}

            {currentStoryAnimate && currentStory.index >= 1 && (
              <div className="story-text">
                <div
                  className="headline animation animated fadeIn"
                  dangerouslySetInnerHTML={{ __html: headline }}
                ></div>

                <b
                  className="animation animated fadeIn"
                  dangerouslySetInnerHTML={{ __html: currentStory.headline }}
                ></b>

                <div
                  className="animation animated fadeIn"
                  dangerouslySetInnerHTML={{ __html: currentStory.text }}
                ></div>

                {currentStoryAnimate && (
                  <div className="story-button-wrapper hide-to-share animation animated fadeIn">
                    <div
                      className="story-button-share"
                      onClick={() => {
                        takeAndShareScreenshot()
                      }}
                    >
                      <ShareIcon />
                      <span>{t('share')}</span>
                    </div>
                    <div className="story-button-share" onClick={onSwipeLeft}>
                      <ChevronForwardIcon />
                      <span>{t('continue')}</span>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="screenshot-watermark show-to-share">
              <img
                className="screenshot-watermark-logo"
                src={`${BASE_URL}/react/assets/img/tiny-affirmation-logo-share.png`}
              ></img>
              <div className="screenshot-watermark-text">
                <h4>{t('screenshot-watermark-headline')}</h4>
                <span>{t('screenshot-watermark-text')}</span>
              </div>
            </div>
          </>
        </StyledStoryPage>
      )}
    </>
  )
}
