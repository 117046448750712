import BottomSpacer from 'components/BottomSpacer';
import PageTitle from 'components/PageTitle';
import TaskCard from 'components/TaskCard';
import BlockButton from 'components/buttons/BlockButton';
import { BASE_URL } from 'constants/URLS';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const HoroscopeDetailFullWrapper = styled.div`
  .article-detail-image {
    position: relative;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    min-height: 430px;
  }

  .page-title,
  .page-title-small {
    text-align: center;
    max-width: 100%;
  }

  .page-content {
    margin-top: -180px;
    position: relative;
    z-index: 9;

    .page-content-text {
      margin: 0 3% 20px;

      h4 {
        font-family: 'clever-german', serif;
        line-height: 1.4;
        font-size: 22px;
        border-top: 2px solid #eee;
        padding-top: 20px;
      }

      h5 {
        font-family: 'clever-german', serif;
        line-height: 1.4;
        font-size: 18px;
        text-decoration: underline;
        text-align: center;
      }

      h6 {
        font-family: 'clever-german', serif;
        line-height: 1.4;
        font-size: 16px;
        text-align: center;
      }

      p,
      .page-text {
        margin-top: 20px;
        font-size: 15px;
        line-height: 1.6;
      }

      ul {
        padding-left: 0;
        margin: 0;
        list-style: none;
        font-size: 15px;

        li {
          position: relative;
          padding-left: 10px;
          margin-bottom: 10px;
          line-height: 1.6;

          &:before {
            content: '•';
            position: absolute;
            left: 0;
            color: #111;
          }
        }
      }
    }
  }
`;

export default function HoroscopeDetailFull({ selectedTranstitDetail, goBack }) {
  const { t } = useTranslation('common');

  return (
    <HoroscopeDetailFullWrapper>
      {selectedTranstitDetail && (
        <img
          className="article-detail-image animated fadeIn"
          src={`${BASE_URL}/api/images/watercolor/tarot.jpg`}
          alt=""
        />
      )}

      <div className="page-content">
        <div>
          <PageTitle title={selectedTranstitDetail.explanation.title} />

          {/*<TaskCard
            title={t('article-page.transit')}
            text={selectedTranstitDetail.explanation.title}
            topic={'horoscope'}
          />*/}

          <div className="page-content-text">
            <h6 className="animated fadeIn">
              <span>{t('article-page.duration')}&nbsp;</span>
              {selectedTranstitDetail.time}
              <span>{t('days')}</span>
            </h6>
            <h6 className="animated fadeIn">
              <span>{t('article-page.transit-is-intense')}</span>
            </h6>
            <p
              className="animated fadeIn"
              dangerouslySetInnerHTML={{ __html: selectedTranstitDetail.explanation.text }}
            ></p>

            <h5>{t('article-page.do')}</h5>
            <ul className="dos animated fadeIn">
              {selectedTranstitDetail.explanation.do.map((item, index) => (
                <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
              ))}
            </ul>
          </div>

          <BottomSpacer />
          <BlockButton text={t('got-it')} onClick={goBack} isBottom></BlockButton>
        </div>
      </div>
    </HoroscopeDetailFullWrapper>
  );
}
