import PageTitle from 'components/PageTitle';
import DownloadIcon from 'components/SVG/DownloadIcon';
import ShareIcon from 'components/SVG/ShareIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledShareImage = styled.div`
  margin-top: 60px;

  .download-preview-wrapper {
    border-bottom: 1px solid #e0e0e0;
    margin: 0 auto;
    max-width: 300px;
    overflow: hidden;

    .download-preview {
      background-size: 250px auto;
      background-position: 50% 0;
      background-repeat: no-repeat;
      height: 310px;
      margin: 0 auto;
      width: 250px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
      border-top-right-radius: 10px 10px;
      border-top-left-radius: 10px 10px;
    }
  }
`;

export default function ShareImage({ imageData, closeModal }) {
  const { t } = useTranslation('common');

  const pageTitle = document.title.replace(/ /g, '-');

  const download = (image, { name = `${pageTitle}`, extension = 'jpg' } = {}) => {
    const a = document.createElement('a');
    a.href = image;
    a.download = createFileName(extension, name);
    a.target = '_blank'; // This line might help with download initiation
    a.dispatchEvent(new MouseEvent('click'));
  };

  const createFileName = (extension, name) => {
    return `${name}.${extension}`;
  };

  const share = async () => {
    const data = {
      files: [
        new File([imageData.blob], `${pageTitle}.png`, {
          type: imageData.blob.type,
        }),
      ],
    };
    try {
      if (!navigator.canShare(data)) {
        throw new Error("Can't share data.", data);
      }
      await navigator.share(data);
    } catch (err) {
      console.error(err.name, err.message);
    }
  };

  return (
    <StyledShareImage>
      <PageTitle title={t('share-image.headline')} text={t('share-image.text')}></PageTitle>

      <div className="download-preview-wrapper">
        <div
          className="download-preview"
          style={{
            backgroundImage: `url(${imageData.fileUrl})`,
          }}
        ></div>
      </div>

      <div className="story-button-wrapper hide-to-share">
        <div className="story-button-share" onClick={() => share()}>
          <ShareIcon />
          <span>{t('share')}</span>
        </div>
        <div className="story-button-share" onClick={() => download(imageData.fileUrl)}>
          <DownloadIcon />
          <span>{t('download')}</span>
        </div>
      </div>
    </StyledShareImage>
  );
}
